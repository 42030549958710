import React from 'react';

interface Props {}

const RemoveBold = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.63603 4.29289C5.24551 3.90237 4.61234 3.90237 4.22182 4.29289C3.83129 4.68342 3.83129 5.31658 4.22182 5.70711L11.5147 13L4.22187 20.2928C3.83135 20.6833 3.83135 21.3165 4.22187 21.707C4.61239 22.0976 5.24556 22.0976 5.63608 21.707L12.9289 14.4142L19.7782 21.2635C20.1687 21.654 20.8019 21.654 21.1924 21.2635C21.5829 20.8729 21.5829 20.2398 21.1924 19.8492L14.3431 13L21.1924 6.15069C21.583 5.76016 21.583 5.127 21.1924 4.73647C20.8019 4.34595 20.1687 4.34595 19.7782 4.73647L12.9289 11.5858L5.63603 4.29289Z"
                fill="white"
            />
        </svg>
    );
};

export default RemoveBold;
