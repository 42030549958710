import React from 'react';

interface Props {}

const ReceiptListFilled = (props: Props) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0017 0.00170278H2.0017C1.86829 -0.00631686 1.73469 0.0140548 1.60973 0.0614728C1.48477 0.108891 1.37129 0.182276 1.27678 0.276784C1.18228 0.371292 1.10889 0.484772 1.06147 0.609732C1.01405 0.734692 0.993685 0.868289 1.0017 1.0017V16.0017L4.0017 14.0017L6.0017 16.0017L8.0017 14.0017L10.0017 16.0017L12.0017 14.0017L15.0017 16.0017V1.0017C15.0097 0.868289 14.9894 0.734692 14.9419 0.609732C14.8945 0.484772 14.8211 0.371292 14.7266 0.276784C14.6321 0.182276 14.5186 0.108891 14.3937 0.0614728C14.2687 0.0140548 14.1351 -0.00631686 14.0017 0.00170278ZM8.0017 10.0017H4.0017V8.0017H8.0017V10.0017ZM8.0017 6.0017H4.0017V4.0017H8.0017V6.0017ZM11.0017 10.0017C10.8039 10.0017 10.6106 9.94305 10.4461 9.83317C10.2817 9.72329 10.1535 9.56711 10.0778 9.38439C10.0021 9.20166 9.98233 9.00059 10.0209 8.80661C10.0595 8.61263 10.1547 8.43445 10.2946 8.2946C10.4344 8.15474 10.6126 8.0595 10.8066 8.02092C11.0006 7.98233 11.2017 8.00214 11.3844 8.07782C11.5671 8.15351 11.7233 8.28168 11.8332 8.44613C11.9431 8.61058 12.0017 8.80392 12.0017 9.0017C12.0017 9.26692 11.8963 9.52127 11.7088 9.70881C11.5213 9.89635 11.2669 10.0017 11.0017 10.0017ZM11.0017 6.0017C10.8039 6.0017 10.6106 5.94305 10.4461 5.83317C10.2817 5.72329 10.1535 5.56711 10.0778 5.38439C10.0021 5.20166 9.98233 5.00059 10.0209 4.80661C10.0595 4.61263 10.1547 4.43445 10.2946 4.2946C10.4344 4.15474 10.6126 4.0595 10.8066 4.02092C11.0006 3.98233 11.2017 4.00214 11.3844 4.07782C11.5671 4.15351 11.7233 4.28168 11.8332 4.44613C11.9431 4.61058 12.0017 4.80392 12.0017 5.0017C12.0017 5.26692 11.8963 5.52127 11.7088 5.70881C11.5213 5.89635 11.2669 6.0017 11.0017 6.0017Z"
                fill="black"
            />
        </svg>
    );
};
export default ReceiptListFilled;
