import React, { memo, useContext, useEffect, useState } from 'react';
import { getCookie } from '../../helpers/cookieUtils';
import { Size, useWindowSize } from '../../hook/useWindowSize';
import { CategoryCtx } from '../../shared/sharedContext';
import { Article } from '../../types/Article';
import { Category } from '../../types/Category';
import ArticleCard, { ArticleCardType } from '../article-card/article-card';
import Icon, { IconType } from '../icons';
import SignUpCard from '../sign-up-card/sign-up-card';
import ArticleGridSkeleton from '../skeleton-load/article-grid-skeleton/article-grid-skeleton';
import styles from './article-grid.module.scss';

interface ArticleGridProps {
    articles: Article[];
    setOffsetArticle: (count: number) => void;
    offsetArticle: number;
    isLoadingMoreArticles: boolean;
}

const CardType = {
    SMALL: 'SMALL',
    LARGE: 'LARGE',
    NEWSLETTER: 'NEWSLETTER',
};

const templates = [
    [CardType.SMALL, CardType.LARGE],
    [CardType.SMALL, CardType.SMALL, CardType.SMALL],
    [CardType.LARGE, CardType.SMALL],
    [CardType.SMALL, CardType.SMALL, CardType.SMALL],
];

const tabletTemplate = [
    [CardType.SMALL, CardType.SMALL],
    [CardType.LARGE, CardType.SMALL],
];

const ArticleGrid = (props: ArticleGridProps) => {
    const { articles, setOffsetArticle, offsetArticle, isLoadingMoreArticles } = props;
    const [haveNewsletterCookie, setNewsLetterCookie] = useState<boolean>(false);
    const windowSize: Size = useWindowSize();
    const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
    const categoryCtx = useContext(CategoryCtx);

    useEffect(() => {
        if (!categoryCtx) return;
        const newsletterSignUpCookie = getCookie('NewsletterSignUp');
        if (newsletterSignUpCookie && newsletterSignUpCookie !== '') {
            setNewsLetterCookie(true);
        }

        return () => {
            if (categoryCtx?.setCategoriesToFilter) {
                categoryCtx.setCategoriesToFilter([]);
            }
        };
    }, []);

    useEffect(() => {
        setFilteredArticles(articles);
        if (categoryCtx?.setAllCategories) {
            const categories: { [key: string]: Category } = {};
            articles.forEach((article) => {
                article.categories.forEach((category) => {
                    categories[category.id] = category;
                });
            });
            categoryCtx?.setAllCategories(Object.values(categories));
        }
    }, [articles]);

    useEffect(() => {
        if (!categoryCtx.categoriesToFilter || categoryCtx.categoriesToFilter.length === 0) {
            setFilteredArticles(articles);
            return;
        }

        const categoryIds = categoryCtx.categoriesToFilter.map((category) => category.id);
        const updateFilteredArticles: Article[] = articles.filter((article) => article.categories.some((category) => categoryIds.includes(category.id)));
        setFilteredArticles(updateFilteredArticles);
    }, [articles, categoryCtx.categoriesToFilter]);

    const getIsMobile = (): boolean => {
        const windowWidth: number = windowSize.width || 0;
        if (windowWidth < 992) {
            return true;
        } else {
            return false;
        }
    };
    const isMobile: boolean = getIsMobile();

    const handleCards = () => {
        const maxTotalRows = filteredArticles.length;
        const cards = [];
        let articleIndex = 0;

        const cardTemplate = (windowSize.width || 0) > 1200 ? templates : tabletTemplate;

        for (let rowNumber = 0; rowNumber < maxTotalRows; rowNumber += 1) {
            const templateIndex = rowNumber % cardTemplate.length;
            const templateRow = cardTemplate[templateIndex];
            if (articleIndex >= maxTotalRows) {
                break;
            }
            for (let colNumber = 0; colNumber < templateRow.length; colNumber++) {
                const cartType = templateRow[colNumber];
                if (articleIndex >= maxTotalRows) {
                    break;
                }
                if (isMobile && !haveNewsletterCookie && rowNumber === 0 && colNumber === 1) {
                    cards.push(<SignUpCard key={`sign_up_card_${articleIndex}`} />);
                    continue;
                }

                if (!isMobile && !haveNewsletterCookie && rowNumber === 1 && colNumber === 2) {
                    cards.push(<SignUpCard key={`sign_up_card_${articleIndex}`} />);
                    continue;
                }

                const article = filteredArticles[articleIndex];
                if (cartType === CardType.SMALL) {
                    cards.push(<ArticleCard key={`article_card_${articleIndex}`} type={ArticleCardType.Small} article={article} />);
                }
                if (cartType === CardType.LARGE) {
                    cards.push(
                        <div className={styles.articleLarge} key={`article_card_${article.url || Math.random()}`}>
                            <ArticleCard type={ArticleCardType.Large} article={article} />
                        </div>
                    );
                }

                articleIndex += 1;
            }
        }

        return cards;
    };

    const handleLoadMoreArticle = () => {
        const count = offsetArticle + (offsetArticle === 0 ? 9 : 10);
        setOffsetArticle(count);
    };

    const renderButtonLoadMore = () => {
        if (articles.length < 9 || offsetArticle > articles.length) {
            return null;
        }

        return (
            <div className={styles.buttonLoadMore + ' ' + (isLoadingMoreArticles ? styles.loading : styles.loaded)} onClick={() => handleLoadMoreArticle()}>
                <div className={styles.buttonLoadMoreContent}>
                    <span>Load more posts</span>
                    <Icon type={IconType.ArrowDown} />
                </div>
            </div>
        );
    };

    const renderArticleContent = () => {
        if (articles.length === 0 && !isLoadingMoreArticles) {
            return null;
        }

        if (articles.length === 0) {
            return <ArticleGridSkeleton />;
        }

        return <div className={styles.articlesContainer}>{handleCards().map((card) => card)}</div>;
    };

    return (
        <div className={styles.articleGrid}>
            {renderArticleContent()}
            <div className={styles.loadMoreContainer}>
                {renderButtonLoadMore()}
                <div className={styles.spinnerLoadMore + ' ' + (isLoadingMoreArticles ? styles.loading : styles.loaded)}></div>
            </div>
        </div>
    );
};

export default memo(ArticleGrid);
