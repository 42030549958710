import React from 'react';

interface Props {}

const CheckOut = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11.5H0.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 9L0.5 11.5L3 14" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 5.5H0.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M0.5 7.5V3.5C0.5 2.948 0.948 2.5 1.5 2.5H14.5C15.052 2.5 15.5 2.948 15.5 3.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H5.5"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.5 0.5V2.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 0.5V2.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default CheckOut;
