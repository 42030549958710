import React from 'react';

interface Props {}

const Download = (props: Props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9V1" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 10L2 10" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 5L6 9L2 5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default Download;
