import React from 'react';

interface Props {}

const Barcode = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 2C5.5 1.72386 5.27614 1.5 5 1.5C4.72386 1.5 4.5 1.72386 4.5 2V14C4.5 14.2761 4.72386 14.5 5 14.5C5.27614 14.5 5.5 14.2761 5.5 14V2Z" stroke="black" />
            <path d="M8.5 2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2V14C7.5 14.2761 7.72386 14.5 8 14.5C8.27614 14.5 8.5 14.2761 8.5 14V2Z" stroke="black" />
            <path
                d="M13.5 2C13.5 1.72386 13.2761 1.5 13 1.5C12.7239 1.5 12.5 1.72386 12.5 2V14C12.5 14.2761 12.7239 14.5 13 14.5C13.2761 14.5 13.5 14.2761 13.5 14V2Z"
                stroke="black"
            />
            <path d="M0.500001 1.5L0.5 14.5" stroke="black" strokeLinecap="round" />
            <path d="M2.5 1.5V14.5" stroke="black" strokeLinecap="round" />
            <path d="M10.5 1.5V14.5" stroke="black" strokeLinecap="round" />
            <path d="M15.5 1.5V14.5" stroke="black" strokeLinecap="round" />
        </svg>
    );
};
export default Barcode;
