import { createContext } from 'react';
import { BreadcrumbItem, BreadcrumbType } from '../components/breadcrumb/breadcrumb';

export type DefaultLayoutContextType = {
    breadcrumbs: BreadcrumbItem[];
    type: BreadcrumbType | undefined;
    setType: (type: BreadcrumbType | undefined) => void
};


export const DefaultLayoutContext = createContext<DefaultLayoutContextType>({} as any);
