import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { getAllCategories } from '../../helpers/BuilderHelper';
import { DEFAULT_LANG, Locale, LOCALE_LINKS, localifyLink, useLocale } from '../../helpers/LangHelper';
import useOnClickOutside from '../../hook/useOutsideClick';
import { Size, useWindowSize } from '../../hook/useWindowSize';
import { Category } from '../../types/Category';
import BusinessHeaderAutoCompleteList from '../auto-complete-form/business-header-autocomplete-list';
import DropdownExplore from '../dropdown-explore/dropdown-explore';
import Icon, { IconType } from '../icons';

import styles from './business-header.module.scss';

interface Props {
    categories: Category[];
}

const BusinessHeader = (props: Props) => {
    const { locale } = useLocale();
    const windowSize: Size = useWindowSize();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isShowDrawerContent, setShowMenu] = useState<boolean>(false);
    const [isShowExplore, setShowExplore] = useState<boolean>(false);
    const [isShowFormSearch, setShowFormSearch] = useState<boolean>(false);

    const windowWidth: number = windowSize.width || 0;

    const [categories, setMappedCategories] = useState<Category[]>([]);
    const navRef = useRef(null);

    useEffect(() => {
        if (windowWidth < 1112) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    useEffect(() => {
        getCategories(props.categories);
    }, [props.categories]);

    useEffect(() => {
        const body = document.body;
        if (!body) {
            return;
        }

        if (isShowDrawerContent) {
            body.classList.add('lock-scroll');
        } else {
            body.classList.remove('lock-scroll');
        }
    }, [isShowDrawerContent]);

    const categoryOrder: string[] = ['News', 'Success Stories', 'Marketing', 'Operations', 'Finance'];
    const getCategories = (categories: Category[]) => {
        if (categories && categories.length > 0) {
            categories.sort((a, b) => {
                const indexOfA = categoryOrder.indexOf(a.name);
                const indexOfB = categoryOrder.indexOf(b.name);

                if (indexOfA !== -1 && indexOfA !== indexOfB) {
                    return indexOfA - indexOfB;
                }
                return a.name.localeCompare(b.name);
            });

            const indx = categories.map((c) => c.name).indexOf(categoryOrder[0]);
            const priorityCategory = categories.slice(indx);
            const normalCategory = categories.slice(0, indx);

            setMappedCategories([...priorityCategory, ...normalCategory]);
        }
    };

    const getGuides = () => {
        return categories
            .filter((category: Category) => {
                if (category.isSeries) {
                    return category;
                }
            })
            .map((category) => ({ name: category.name, guideUrl: category.categoryUrl ?? '' }));
    };

    const sortCategoriesForDropdown = (categories: Category[]) => {
        if (categories && categories.length > 0) {
            let categoriesMap: any = [];
            for (let i = 0; i < categoryOrder.length; i++) {
                const categoryFilter = categories.find((category: Category) => category.name === categoryOrder[i]);
                categoriesMap.push(categoryFilter);
            }
            return categoriesMap;
        }
    };

    useOnClickOutside(navRef, () => {
        setShowExplore(false);
    });

    const toggleShowHideExplore = () => {
        setShowExplore(!isShowExplore);
    };
    const handleClickToggleMenu = () => {
        setShowMenu((oldState) => !oldState);
    };
    const handleToggleFormSearch = () => {
        setShowFormSearch(!isShowFormSearch);
    };

    const signupButton = () => (
        <a rel="noreferrer" href={(LOCALE_LINKS[locale] || LOCALE_LINKS[DEFAULT_LANG])!.signup} className={styles.buttonSignUp}>
            Sign up
        </a>
    );
    const renderDropDownExplore = () => {
        if (isMobile) {
            return null;
        }

        return (
            <>
                <div className={styles.buttonExplore + ' ' + (isShowExplore ? styles.buttonActive : '')} onClick={() => toggleShowHideExplore()}>
                    Explore
                    <div className={styles.iconDown}>
                        <Icon type={IconType.Down} />
                    </div>
                </div>
                <DropdownExplore isToggle={isShowExplore} categories={sortCategoriesForDropdown(categories)} guides={getGuides()} />
            </>
        );
    };
    const renderButtonToggleDrawer = () => {
        if (isMobile) {
            return (
                <div className={styles.buttonToggleDrawer}>
                    <div className={styles.iconMenu} onClick={() => handleClickToggleMenu()}>
                        {isShowDrawerContent ? <Icon type={IconType.RemoveBold} /> : <Icon type={IconType.MenuHeader} />}
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.menuRight}>
                <BusinessHeaderAutoCompleteList isShown={isShowFormSearch} onClickButtonSearch={() => handleToggleFormSearch()} />
                <div className={styles.verticalLine} />
                {signupButton()}
            </div>
        );
    };
    const renderDrawerContent = () => {
        if (!isShowDrawerContent) {
            return null;
        }
        const { width = 0, height = 0 } = windowSize;
        return (
            <div
                className={styles.drawerMenu}
                style={{
                    height: width <= 1112 ? height - 64 : undefined,
                }}
            >
                <div className={styles.drawerMenuButton}>
                    <BusinessHeaderAutoCompleteList isShown={isShowFormSearch} onClickButtonSearch={() => handleToggleFormSearch()} />
                    {!isShowFormSearch && <div className={styles.accountGroup}>{signupButton()}</div>}
                </div>
                {!isShowFormSearch && <DropdownExplore isToggle={true} categories={sortCategoriesForDropdown(categories)} />}
            </div>
        );
    };

    const renderLogo = () => {
        if (locale === Locale.GreatBritain) {
            return <Icon key={Math.random()} type={IconType.LogoClearpay} />;
        }

        return <Icon key={Math.random()} type={IconType.AfterPay} />;
    };
    return (
        <div className={styles.articleHeaderWrapper} ref={navRef}>
            <div className={styles.articleHeaderContainer + ' ' + (isShowDrawerContent ? styles.toggleMenu : '')}>
                <div className={styles.logoWrapper}>
                    <a href={(LOCALE_LINKS[locale] || LOCALE_LINKS[Locale.USA])!.home}>
                        <Icon type={IconType.LogoAfterPay} />
                    </a>
                    <a href={localifyLink(`/${locale}/business/access`, locale)} className={styles.businessHomepageLinks}>
                        <div className={styles.iconAfterPay}>{renderLogo()}</div>
                        <div className={styles.businessIcon}>
                            <Icon type={IconType.BusinessSmall} />
                            <Icon type={IconType.Business} />
                        </div>
                    </a>

                    {renderDropDownExplore()}
                </div>
                {renderButtonToggleDrawer()}
            </div>
            {renderDrawerContent()}
        </div>
    );
};

export default BusinessHeader;
