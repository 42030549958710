import React from 'react';

interface Props {}

const FacebookFilled = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.14945 5.85957C9.14945 5.7349 9.16677 5.46565 9.2014 5.05355C9.23863 4.64058 9.39446 4.20856 9.6741 3.7549C9.95115 3.30297 10.4005 2.89693 11.0256 2.53764C11.648 2.18008 12.5476 2 13.7215 2H17.0288V5.60417H14.6072C14.3674 5.60417 14.144 5.69421 13.9423 5.87169C13.7423 6.04918 13.6393 6.218 13.6393 6.3747V8.61703H17.0279C16.9924 9.05597 16.9534 9.48279 16.9084 9.8949L16.7742 10.984C16.7206 11.352 16.666 11.6931 16.6123 12.0074H13.6151V22H9.14945V12.0065H6.97119V8.61703H9.14945V5.85957Z"
                fill="#141414"
            />
        </svg>
    );
};
export default FacebookFilled;
