import React from 'react';

interface Props {}

const Remove = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 5.5L5.5 19.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M19.5 19.5L5.5 5.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default Remove;
