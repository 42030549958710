export enum COLORS {
    Mint = 'Mint',
    Black = 'Black',
    White = 'White',
    Grey = 'Grey',
}

export interface VariantColor {
    color?: COLORS;
}

export interface AssetVariantType {
    /** Asset color */
    colors?: VariantColor[];
    /** Background in the UI. Default is white */
    backgroundColor?: string;
    previewUrl?: string;
    /** Download URL */
    downloadUrl: string;
    /** Example: svg, png, ai, eps,... */
    extension: string;
    /** Example: 1x 2x png svg... showed on tooltip **/
    variantName: string;
    /** Implemented page preview **/
    pagePreviewImage?: string;
    /**
     * Hide on card slider
     */
    hide?: boolean;
    fileName?: string;
}

export interface AssetType {
    /**
     * Applicable for selecting asset
     */
    id?: string;
    name?: string;
    /** Logos, Banners, Fonts,... */
    type?: string;
    keyMessages?: string[];
    /**
     * @deprecated
     */
    themedAssets?: string[];
    variants: AssetVariantType[];
    /**
     * Hide on UI but available for download
     */
    hide?: boolean;
    /**
     * Show on UI but unavailable for download
     */
    skipDownload?: boolean;
}

interface VariantRequestZipType {
    downloadUrl: string;
    path: string;
    filename: string;
    extension: string;
}

export interface RequestZipType {
    name?: string;
    variants: VariantRequestZipType[];
}

export interface ResourceType {
    id?: string;
    type?: string;
    title?: string;
    description?: string;
    /**
     * Example: Logo page
     */
    description2?: string;
    assets?: AssetType[];
}
