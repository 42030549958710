import React from 'react';

interface Props {}

const Shop = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 13.5V22.5H20.5V13.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path
                d="M4.50001 2.5L1.50001 7.5C1.4985 8.17436 1.72485 8.82946 2.14235 9.35905C2.55985 9.88864 3.14402 10.2617 3.8001 10.4176C4.45619 10.5735 5.14572 10.5033 5.75686 10.2182C6.36801 9.93311 6.86492 9.44992 7.16701 8.847C7.41349 9.34349 7.79369 9.76131 8.26479 10.0534C8.7359 10.3455 9.2792 10.5003 9.83351 10.5003C10.3878 10.5003 10.9311 10.3455 11.4022 10.0534C11.8733 9.76131 12.2535 9.34349 12.5 8.847C12.7465 9.34349 13.1267 9.76131 13.5978 10.0534C14.0689 10.3455 14.6122 10.5003 15.1665 10.5003C15.7208 10.5003 16.2641 10.3455 16.7352 10.0534C17.2063 9.76131 17.5865 9.34349 17.833 8.847C18.1351 9.44992 18.632 9.93311 19.2432 10.2182C19.8543 10.5033 20.5438 10.5735 21.1999 10.4176C21.856 10.2617 22.4402 9.88864 22.8577 9.35905C23.2752 8.82946 23.5015 8.17436 23.5 7.5L20.5 2.5H4.50001Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path d="M10.5 22.5V16.5H14.5V22.5" stroke="black" strokeMiterlimit="10" />
        </svg>
    );
};
export default Shop;
