import { Locale } from './LangHelper';

export enum NavItemLocations {
    App = 'App',
    AppChips = 'App Chips',

    AllCategories = 'All Categories',
    Retailers = 'Retailers',

    WebTop = 'Web top',

    Footer = 'Footer',
    FooterRegional = 'Regional Footer',
}

export type NavigationLocationDTO = {
    id: string;
    type: string;
    attributes: {
        name: NavItemLocations;
    };
    relationships: {
        [name: string]: {
            data: { type: string; id: string }[];
        };
    };
};

export type NavigationItemDTO = {
    id: string;
    type: string;
    attributes: {
        row: number;
        column: number;
        link: string;
        bold: boolean;
        title: string;
        color: {
            name: string;
            hex_code: string;
        };
        category_id: any;
        navigation_id: number;
        parent_id: number;
        updated_at: string;
        slug: null | string;
    };
    relationships: {
        [name: string]: any;
    };
    links: {
        self: string;
    };
};

export type NavItem = {
    id: string;
    isLead: boolean;

    name: string;
    link: string;

    children: NavItem[];
};

export type FooterMatrix = {
    [row: number]: {
        [column: number]: NavItem;
    };
};

let cachedFetchedFooterItems: { [locale in Locale]?: FooterMatrix[] } = {};

export const STORE_API_PATH = process.env.AP_STORE_ENDPOINT ?? 'https://store-directory-api-staging.afterpay.com/api/v1/navigations?locale=';

let storeFetchAPIPromise: { [locale in Locale]?: Promise<FooterMatrix[]> } = {};

export async function fetchGlobalNavItems(locale: Locale): Promise<FooterMatrix[]> {
    if (storeFetchAPIPromise[locale]) {
        return (await storeFetchAPIPromise[locale]) as FooterMatrix[];
    }

    if (cachedFetchedFooterItems[locale]) {
        return cachedFetchedFooterItems[locale] as FooterMatrix[];
    }

    storeFetchAPIPromise[locale] = (async () => {
        const response = await fetch(STORE_API_PATH + locale);

        // TODO: status checks
        const dataResponse = await response.json();

        const regionalFooterItems: FooterMatrix = {};
        const footerItems: FooterMatrix = {};

        let regionFooterID: number;
        let footerID: number;

        // we only care about Footer & Regional footer, so let's start by filtering the pool
        dataResponse.data.forEach((item: NavigationLocationDTO) => {
            if (item.attributes.name === NavItemLocations.Footer) {
                footerID = parseInt(item.id);
            } else if (item.attributes.name === NavItemLocations.FooterRegional) {
                regionFooterID = parseInt(item.id);
            }
        });

        const mappedNavItems: { [id: string]: NavigationItemDTO } = {};
        dataResponse.included.forEach((item: NavigationItemDTO) => {
            if ([regionFooterID, footerID].includes(item.attributes.navigation_id)) {
                mappedNavItems[item.id] = item;
            }
        });

        const placeNavItemInMatrix = (item: NavigationItemDTO, isRegional = false) => {
            const matrix = isRegional ? regionalFooterItems : footerItems;

            if (matrix[item.attributes.column] === undefined) {
                matrix[item.attributes.column] = {};
            }

            let targetColumn;
            let targetRow;
            let isLead = false;

            if (item.attributes.parent_id) {
                const parentItem = mappedNavItems[item.attributes.parent_id];
                if (!matrix[parentItem.attributes.column][parentItem.attributes.row]) {
                    placeNavItemInMatrix(parentItem, isRegional);
                }

                targetColumn = parentItem.attributes.column;
                targetRow = item.attributes.row + 1;
            } else {
                targetColumn = item.attributes.column;
                targetRow = item.attributes.row;

                if (!isRegional) {
                    isLead = true;
                }
            }

            matrix[targetColumn][targetRow] = {
                name: item.attributes.title,
                id: item.id,
                link: item.attributes.link,
                children: [],
                isLead,
            };
        };

        Object.values(mappedNavItems).forEach((item: NavigationItemDTO) => {
            placeNavItemInMatrix(item, item.attributes.navigation_id == regionFooterID);
        });

        return [footerItems, regionalFooterItems];
    })();

    const response = await storeFetchAPIPromise[locale];

    delete storeFetchAPIPromise[locale];
    cachedFetchedFooterItems[locale] = response;

    return response as FooterMatrix[];
}
