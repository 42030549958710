import React from 'react';

interface Props {}

const CheckIn = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11.5H15.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 9L15.5 11.5L13 14" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.5 5.5H15.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M15.5 7.5V3.5C15.5 2.948 15.052 2.5 14.5 2.5H1.5C0.948 2.5 0.5 2.948 0.5 3.5V14.5C0.5 15.052 0.948 15.5 1.5 15.5H10.5"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M4.5 0.5V2.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 0.5V2.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default CheckIn;
