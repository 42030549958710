import React from 'react';

interface Props {}

const Triangle = (props: Props) => {
    return (
        <svg width="41" height="47" viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.131655 0.537983L40.0226 23.569L0.131656 46.6001L0.131655 0.537983Z" fill="white" />
        </svg>
    );
};

export default Triangle;
