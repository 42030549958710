import React from 'react';

interface Props {}

const External = (props: Props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1.99997L6 6" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 2H10V6" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 2H2V10H10V8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default External;
