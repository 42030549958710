import React from 'react';

interface Props {}

const ReceiptList = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 1.5H21.5V22.5L18.5 20.5L15.5 22.5L12.5 20.5L9.5 22.5L6.5 20.5L3.5 22.5V1.5Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M7.5 7.5H12.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M16.5 7.5H17.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M7.5 11.5H12.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M16.5 11.5H17.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M7.5 15.5H12.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M16.5 15.5H17.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default ReceiptList;
