import React from 'react';

interface Props {}

const MenuHeader = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5L23 5C23.5523 5 24 4.55228 24 4C24 3.44772 23.5523 3 23 3L1 3ZM0 12C0 11.4477 0.447715 11 1 11L23 11C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13L1 13C0.447715 13 0 12.5523 0 12ZM0 20C0 19.4477 0.447715 19 1 19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21L1 21C0.447715 21 0 20.5523 0 20Z"
                fill="white"
            />
        </svg>
    );
};

export default MenuHeader;
