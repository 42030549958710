import React from 'react';
import AfterPay from './Icons/AfterPay';
import ArrowBack from './Icons/ArrowBack';
import ArrowDown from './Icons/ArrowDown';
import ArrowRightBig from './Icons/ArrowRightBig';
import ArrowRightMedium from './Icons/ArrowRightMedium';
import ArrowRightOutline from './Icons/ArrowRightOutline';
import ArrowRightSmall from './Icons/ArrowRightSmall';
import ArrowUpBig from './Icons/ArrowUpBig';
import Barcode from './Icons/Barcode';
import Business from './Icons/Business';
import BusinessSmall from './Icons/BusinessSmall';
import Chat from './Icons/Chat';
import CheckIn from './Icons/CheckIn';
import CheckOut from './Icons/CheckOut';
import Close from './Icons/Close';
import CreditCard from './Icons/CreditCard';
import CreditCardBig from './Icons/CreditCardBig';
import CreditCardFilled from './Icons/CreditCardFilled';
import Dollar from './Icons/Dollar';
import Dot from './Icons/Dot';
import Down from './Icons/Down';
import Download from './Icons/Download';
import External from './Icons/External';
import FacebookFilled from './Icons/FacebookFilled';
import FChat from './Icons/FChat';
import Filter from './Icons/Filter';
import FlashFilled from './Icons/FlashFilled';
import HomeFilled from './Icons/HomeFilled';
import Info from './Icons/Info';
import InfoBig from './Icons/InfoBig';
import Instagram from './Icons/Instagram';
import Left from './Icons/Left';
import Like from './Icons/Like';
import LinkedInFilled from './Icons/LinkedInFilled';
import LogoAfterPay from './Icons/LogoAfterPay';
import LogoAfterPayBusiness from './Icons/LogoAfterPayBusiness';
import Menu from './Icons/Menu';
import MenuHeader from './Icons/MenuHeader';
import OpeningTime from './Icons/OpeningTime';
import PasswordHide from './Icons/PasswordHide';
import PasswordShow from './Icons/PasswordShow';
import PeopleCircle from './Icons/PeopleCircle';
import PeopleCircleFilled from './Icons/PeopleCircleFilled';
import PeopleCircleOutline from './Icons/PeopleCircleOutline';
import PieChart from './Icons/PieChart';
import ReadBook from './Icons/ReadBook';
import ReceiptList from './Icons/ReceiptList';
import ReceiptListFilled from './Icons/ReceiptListFilled';
import Remove from './Icons/Remove';
import RemoveBold from './Icons/RemoveBold';
import RemoveCircle from './Icons/RemoveCircle';
import Resource from './Icons/Resource';
import Right from './Icons/Right';
import Schedule from './Icons/Schedule';
import Search from './Icons/Search';
import SearchBig from './Icons/SearchBig';
import SettingFilled from './Icons/SettingFilled';
import SettingOutline from './Icons/SettingOutline';
import Shop from './Icons/Shop';
import ShoppingBag from './Icons/ShoppingBag';
import Subscribed from './Icons/Subscribed';
import Support from './Icons/Support';
import Telegram from './Icons/Telegram';
import Trash from './Icons/Trash';
import TwitterFilled from './Icons/TwitterFilled';
import Up from './Icons/Up';
import WorldFilled from './Icons/WorldFilled';
import LogoClearpay from './Icons/LogoClearpay';
import Triangle from './Icons/Triangle';
import Smartphone from './Icons/Smartphone';
import Monitor from './Icons/Monitor';
import BookOpen from './Icons/BookOpen';
import Compass from './Icons/Compass';
import PenTool from './Icons/PenTool';
import ThumbsUp from './Icons/ThumbsUp';
import Users from './Icons/Users';
import Download2 from './Icons/Download2';
import Copy from './Icons/Copy';
import AfterpayBadge from './Icons/AfterpayBadge';
import LogoAfterpayBlack from './Icons/LogoAfterpayBlack';
import Check from './Icons/Check';
import Email from './Icons/Email';
import Store from './Icons/Store';
import Monitor2 from './Icons/Monitor2';

export enum IconType {
    ArrowBack = 'Arrow-Back',
    ArrowDown = 'Arrow-Down',
    ArrowRightBig = 'Arrow-Right-Big',
    ArrowRightMedium = 'Arrow-Right-Medium',
    ArrowRightOutLine = 'Arrow-Right-Outline',
    ArrowRightSmall = 'Arrow-Right-Small',
    ArrowUpBig = 'Arrow-Up-Big',
    Barcode = 'Barcode',
    Chat = 'Chat',
    CheckIn = 'Check-In',
    CheckOut = 'Check-Out',
    Close = 'Close',
    CreditCard = 'Credit-Card',
    CreditCardBig = 'Credit-Card-Big',
    CreditCardFilled = 'Credit-Card-Filled',
    Dollar = 'Dollar',
    Dot = 'Dot',
    Down = 'Down',
    Download = 'Download',
    External = 'External',
    FacebookFilled = 'Facebook-Filled',
    FChat = 'F-Chat',
    FlashFilled = 'Flash-Filled',
    HomeFilled = 'Home-Filled',
    Info = 'Info',
    InfoBig = 'Info-Big',
    Instagram = 'Instagram',
    Left = 'Left',
    Like = 'Like',
    LinkedInFilled = 'LinkedIn-Filled',
    Menu = 'Menu',
    OpeningTime = 'Opening-Time',
    PasswordHide = 'Password-Hide',
    PasswordShow = 'Password-Show',
    PeopleCircle = 'People-Circle',
    PeopleCircleFilled = 'People-Circle-Filled',
    PeopleCircleOutline = 'People-Circle-Outline',
    PieChart = 'Pie-Chart',
    ReceiptList = 'Receipt-List',
    ReceiptListFilled = 'Receipt-List-Filled',
    Remove = 'Remove',
    Right = 'Right',
    Schedule = 'Schedule',
    Search = 'Search',
    SearchBig = 'Search-Big',
    SettingFilled = 'Setting-Filled',
    SettingOutline = 'Setting-Outline',
    Shop = 'Shop',
    ShoppingBag = 'Shopping-Bag',
    Support = 'Support',
    Telegram = 'Telegram',
    Trash = 'Trash',
    TwitterFilled = 'Twitter-Filled',
    Up = 'Up',
    WorldFilled = 'World-Filled',
    Subscribed = 'Subscribed',
    Filter = 'Filter',
    LogoAfterPay = 'Logo-AfterPay',
    LogoAfterPayBusiness = 'Logo-AfterPay-Business',
    MenuHeader = 'Menu-Header',
    ReadBook = 'Read-book',
    Resource = 'Resource',
    RemoveCircle = 'Remove-Circle',
    Business = 'Business',
    AfterPay = 'AfterPay',
    BusinessSmall = 'Business-Small',
    RemoveBold = 'Remove-Bold',
    LogoClearpay = 'LogoClearpay',
    Triangle = 'Triangle',
    Smartphone = 'Smartphone',
    Monitor = 'Monitor',
    Monitor2 = 'Monitor2',
    BookOpen = 'BookOpen',
    Compass = 'Compass',
    PenTool = 'PenTool',
    ThumbsUp = 'ThumbsUp',
    Users = 'Users',
    Download2 = 'Download2',
    Copy = 'Copy',
    AfterpayBadge = 'AfterpayBadge',
    LogoAfterpayBlack = 'LogoAfterpayBlack',
    Check = 'Check',
    Email = 'Email',
    Store = 'Store',
    None = 'None',
}
interface IconProps {
    type: IconType | undefined;
}

const Icon = ({ type }: IconProps) => {
    switch (type) {
        case IconType.ArrowBack:
            return <ArrowBack />;
        case IconType.ArrowDown:
            return <ArrowDown />;
        case IconType.ArrowRightBig:
            return <ArrowRightBig />;
        case IconType.ArrowRightMedium:
            return <ArrowRightMedium />;
        case IconType.ArrowRightOutLine:
            return <ArrowRightOutline />;
        case IconType.ArrowRightSmall:
            return <ArrowRightSmall />;
        case IconType.ArrowUpBig:
            return <ArrowUpBig />;
        case IconType.Barcode:
            return <Barcode />;
        case IconType.Chat:
            return <Chat />;
        case IconType.CheckIn:
            return <CheckIn />;
        case IconType.CheckOut:
            return <CheckOut />;
        case IconType.Close:
            return <Close />;
        case IconType.CreditCard:
            return <CreditCard />;
        case IconType.CreditCardBig:
            return <CreditCardBig />;
        case IconType.CreditCardFilled:
            return <CreditCardFilled />;
        case IconType.Dollar:
            return <Dollar />;
        case IconType.Dot:
            return <Dot />;
        case IconType.Down:
            return <Down />;
        case IconType.Download:
            return <Download />;
        case IconType.External:
            return <External />;
        case IconType.FacebookFilled:
            return <FacebookFilled />;
        case IconType.FChat:
            return <FChat />;
        case IconType.FlashFilled:
            return <FlashFilled />;
        case IconType.HomeFilled:
            return <HomeFilled />;
        case IconType.Info:
            return <Info />;
        case IconType.InfoBig:
            return <InfoBig />;
        case IconType.Instagram:
            return <Instagram />;
        case IconType.Left:
            return <Left />;
        case IconType.Like:
            return <Like />;
        case IconType.LinkedInFilled:
            return <LinkedInFilled />;
        case IconType.Menu:
            return <Menu />;
        case IconType.OpeningTime:
            return <OpeningTime />;
        case IconType.PasswordHide:
            return <PasswordHide />;
        case IconType.PasswordShow:
            return <PasswordShow />;
        case IconType.PeopleCircle:
            return <PeopleCircle />;
        case IconType.PeopleCircleFilled:
            return <PeopleCircleFilled />;
        case IconType.PeopleCircleOutline:
            return <PeopleCircleOutline />;
        case IconType.PieChart:
            return <PieChart />;
        case IconType.ReceiptList:
            return <ReceiptList />;
        case IconType.ReceiptListFilled:
            return <ReceiptListFilled />;
        case IconType.Remove:
            return <Remove />;
        case IconType.Right:
            return <Right />;
        case IconType.Schedule:
            return <Schedule />;
        case IconType.Search:
            return <Search />;
        case IconType.SearchBig:
            return <SearchBig />;
        case IconType.SettingFilled:
            return <SettingFilled />;
        case IconType.SettingOutline:
            return <SettingOutline />;
        case IconType.Shop:
            return <Shop />;
        case IconType.ShoppingBag:
            return <ShoppingBag />;
        case IconType.Support:
            return <Support />;
        case IconType.Telegram:
            return <Telegram />;
        case IconType.Trash:
            return <Trash />;
        case IconType.TwitterFilled:
            return <TwitterFilled />;
        case IconType.Up:
            return <Up />;
        case IconType.WorldFilled:
            return <WorldFilled />;
        case IconType.Subscribed:
            return <Subscribed />;
        case IconType.Filter:
            return <Filter />;
        case IconType.LogoAfterPay:
            return <LogoAfterPay />;
        case IconType.LogoAfterPayBusiness:
            return <LogoAfterPayBusiness />;
        case IconType.MenuHeader:
            return <MenuHeader />;
        case IconType.ReadBook:
            return <ReadBook />;
        case IconType.Resource:
            return <Resource />;
        case IconType.RemoveCircle:
            return <RemoveCircle />;
        case IconType.Business:
            return <Business />;
        case IconType.AfterPay:
            return <AfterPay />;
        case IconType.BusinessSmall:
            return <BusinessSmall />;
        case IconType.RemoveBold:
            return <RemoveBold />;
        case IconType.LogoClearpay:
            return <LogoClearpay />;
        case IconType.Triangle:
            return <Triangle />;
        case IconType.Smartphone:
            return <Smartphone />;
        case IconType.Monitor:
            return <Monitor />;
        case IconType.Monitor2:
            return <Monitor2 />;
        case IconType.BookOpen:
            return <BookOpen />;
        case IconType.Compass:
            return <Compass />;
        case IconType.PenTool:
            return <PenTool />;
        case IconType.ThumbsUp:
            return <ThumbsUp />;
        case IconType.Users:
            return <Users />;
        case IconType.Download2:
            return <Download2 />;
        case IconType.Copy:
            return <Copy />;
        case IconType.AfterpayBadge:
            return <AfterpayBadge />;
        case IconType.LogoAfterpayBlack:
            return <LogoAfterpayBlack />;
        case IconType.Check:
            return <Check />;
        case IconType.Email:
            return <Email />;
        case IconType.Store:
            return <Store />;
        default:
            return null;
    }
};

export default Icon;
