import React from 'react';

interface Props {}

const LogoAfterPay = (props: Props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#B2FCE4" />
            <path
                d="M21.9558 10.9361L17.2124 8.27495C15.8201 7.49382 14.0796 8.46994 14.0796 10.0333V10.3063C14.0796 10.553 14.2153 10.7812 14.4348 10.9039L15.3304 11.4063C15.5935 11.5543 15.9215 11.3696 15.9215 11.0749V10.3958C15.9215 10.0563 16.2991 9.84409 16.6012 10.0138L20.7097 12.3194C21.0118 12.4891 21.0118 12.9135 20.7097 13.0822L16.6012 15.3877C16.2991 15.5575 15.9215 15.3453 15.9215 15.0057V14.6444C15.9215 13.081 14.1811 12.1037 12.7876 12.886L8.04425 15.5471C6.65192 16.3283 6.65192 18.2828 8.04425 19.0639L12.7876 21.7251C14.1799 22.5062 15.9215 21.5301 15.9215 19.9667V19.6937C15.9215 19.447 15.7858 19.2199 15.5664 19.0961L14.6708 18.5925C14.4077 18.4445 14.0796 18.6292 14.0796 18.924V19.603C14.0796 19.9426 13.7021 20.1548 13.4 19.985L9.29144 17.6795C8.98938 17.5097 8.98938 17.0853 9.29144 16.9155L13.4 14.61C13.7021 14.4402 14.0796 14.6524 14.0796 14.992V15.3533C14.0796 16.9167 15.8201 17.894 17.2124 17.1117L21.9558 14.4506C23.3481 13.6717 23.3481 11.7172 21.9558 10.9361Z"
                fill="black"
            />
        </svg>
    );
};

export default LogoAfterPay;
