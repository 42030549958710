import React from 'react';

interface Props {}

const Store = (props: Props) => {
    return (
        <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9H8V25H16V9Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 9H19V15H25V9Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 25V1L4 1V25H28Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 25H32" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 5H32" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default Store;
