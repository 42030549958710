import React from 'react';

interface Props {}

const HomeFilled = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M10.9996 16.0002H12.9996C13.5996 16.0002 13.9996 15.6002 13.9996 15.0002V8.0002H14.9996C15.2996 8.0002 15.5996 7.9002 15.7996 7.7002C16.1996 7.3002 16.0996 6.7002 15.6996 6.3002L8.69956 0.300195C8.29956 0.000195384 7.79956 0.000195384 7.39956 0.300195L0.399562 6.3002C-0.000438601 6.7002 -0.100438 7.3002 0.299562 7.7002C0.399562 7.9002 0.699562 8.0002 0.999562 8.0002C1.19956 8.0002 1.99956 8.0002 1.99956 8.0002V15.0002C1.99956 15.6002 2.39956 16.0002 2.99956 16.0002H4.99956C5.59956 16.0002 5.99956 15.6002 5.99956 15.0002V12.0002H9.99956V15.0002C9.99956 15.6002 10.3996 16.0002 10.9996 16.0002Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default HomeFilled;
