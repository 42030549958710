import React from 'react';

interface Props {}

const Like = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 21.9999H18.229C18.6568 22 19.0734 21.8629 19.4175 21.6088C19.7616 21.3546 20.0152 20.9968 20.141 20.5879L22.91 11.5879C23.002 11.2889 23.0225 10.9725 22.97 10.6641C22.9175 10.3558 22.7934 10.064 22.6076 9.8123C22.4218 9.5606 22.1796 9.35599 21.9004 9.2149C21.6212 9.07381 21.3128 9.00018 21 8.99993H14V4.99993C14 2.62693 12.749 1.50593 11.236 1.13993C11.0884 1.10526 10.9348 1.10441 10.7868 1.13744C10.6389 1.17048 10.5002 1.23654 10.3814 1.33069C10.2625 1.42484 10.1665 1.54464 10.1004 1.68113C10.0344 1.81763 10.0001 1.96729 10 2.11893V5.99993L5 11.9999"
                stroke="black"
                strokeMiterlimit="10"
            />
            <path d="M5 10H1V22H5V10Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default Like;
