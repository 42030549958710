import React from 'react';

interface Props {}

const ArrowDown = (props: Props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11.5V0.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 7.5L6 11.5L2 7.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default ArrowDown;
