import React from 'react';

interface Props {}

const Left = (props: Props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 3L4 6L7 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default Left;
