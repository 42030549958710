import React from 'react';

interface Props {}

const CreditCardBig = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.5H23" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M1 11.5H23" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path
                d="M21 21.5H3C1.895 21.5 1 20.605 1 19.5V5.5C1 4.395 1.895 3.5 3 3.5H16H21C22.105 3.5 23 4.395 23 5.5V19.5C23 20.605 22.105 21.5 21 21.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path d="M5 16.5H10" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M18 16.5H19" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default CreditCardBig;
