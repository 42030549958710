import React from 'react';

interface Props {}

const Schedule = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 16.5V18.5H19.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path
                d="M17.5 23.5C20.2614 23.5 22.5 21.2614 22.5 18.5C22.5 15.7386 20.2614 13.5 17.5 13.5C14.7386 13.5 12.5 15.7386 12.5 18.5C12.5 21.2614 14.7386 23.5 17.5 23.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path d="M9.5 22.5H1.5V4.5H23.5V11.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M7.5 1.5V4.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M17.5 1.5V4.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M1.5 8.5H23.5" stroke="black" strokeMiterlimit="10" />
        </svg>
    );
};
export default Schedule;
