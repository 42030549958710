import React from 'react';

interface Props {}

const Dot = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM13.91 9H8.677L6.929 4.628L5.071 5.372L6.523 9H2.09C2.035 8.674 2 8.341 2 8C2 4.691 4.691 2 8 2C11.309 2 14 4.691 14 8C14 8.341 13.965 8.674 13.91 9Z"
                fill="black"
            />
        </svg>
    );
};
export default Dot;
