import React from 'react';

interface Props {}

const PeopleCircle = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9529 13.6211C12.5057 12.6867 11.8033 11.8979 10.9269 11.3457C10.0505 10.7935 9.03575 10.5005 7.99988 10.5005C6.964 10.5005 5.94926 10.7935 5.07283 11.3457C4.19641 11.8979 3.49404 12.6867 3.04688 13.6211"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default PeopleCircle;
