import React from 'react';

interface Props {}

const SearchBig = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5002 22.4998L16.1562 16.1558" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path
                d="M10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
export default SearchBig;
