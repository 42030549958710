import React from 'react';

interface Props {}

const ArrowBack = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 16.5H16.5C20.366 16.5 23.5 13.366 23.5 9.5C23.5 5.634 20.366 2.5 16.5 2.5H9.5" stroke="black" strokeMiterlimit="10" />
            <path d="M7.5 11.5L2.5 16.5L7.5 21.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default ArrowBack;
