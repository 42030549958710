import React from 'react';

interface Props {}

const Dollar = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 23.5C18.5751 23.5 23.5 18.5751 23.5 12.5C23.5 6.42487 18.5751 1.5 12.5 1.5C6.42487 1.5 1.5 6.42487 1.5 12.5C1.5 18.5751 6.42487 23.5 12.5 23.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path d="M12.5 5.5V19.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path
                d="M15.875 7.71876C14.254 6.82776 9.148 6.16276 9.148 9.23176C9.148 12.9058 15.632 11.6088 15.632 14.8508C15.632 18.0928 11.418 17.8488 8.5 16.7958"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
export default Dollar;
