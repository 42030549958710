import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getLocaleFromRoute, getLocalePrettyName, LIVE_LOCALES, Locale, LOCALE_LINKS, replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import Dropdown, { DropDown } from '../dropdown-selector/dropdown-selector';
import Icon, { IconType } from '../icons';
import styles from './business-footer.module.scss';
import { fetchGlobalNavItems, FooterMatrix, NavItem } from '../../helpers/NavHelper';
interface FooterCategoryItem {
    title: string;
    url: string;
    isLead: boolean;
}

interface Props {
    footerData?: FooterMatrix[];
}

const renderSingleBlock = (footerCategoryItems: FooterCategoryItem[]) => {
    return (
        <ul className={styles.footerBlock}>
            {footerCategoryItems.map((item: FooterCategoryItem, index: number) => {
                return (
                    <li key={`footer_item_${index}`}>
                        {item.isLead ? (
                            <div className={styles.footerTitle}>{item.title}</div>
                        ) : (
                            <a href={item.url} target="_self">
                                {item.title}
                            </a>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const BusinessFooter = (props: Props) => {
    const route = useRouter();
    const { locale } = useLocale();

    const [footerItems, setFooterItems] = useState<FooterMatrix[]>(props.footerData ?? []);
    useEffect(() => {
        if (!props.footerData) {
            let cancelled = false;

            fetchGlobalNavItems(locale).then((items) => {
                if (!cancelled) {
                    setFooterItems(items);
                }
            });

            return () => {
                cancelled = true;
            };
        }
    }, []);

    const handleOnLocationChange = (location: DropDown | undefined) => {
        if (!location) return;

        const locale = location.id as Locale;
        const oldLocale = getLocaleFromRoute(route.asPath);
        let newUrl = route.asPath.replace(oldLocale, locale);
        if (newUrl.indexOf(locale) < 0) {
            newUrl = replaceOrAddLocale(newUrl, locale);
        }

        window.location.href = location.urlRedirect ?? newUrl;
    };

    const dropdownOptions: DropDown[] = LIVE_LOCALES.map((thisLocale: Locale, index: number) => {
        let origin = '';
        if (process.env.NODE_ENV === 'production') {
            origin = LOCALE_LINKS[thisLocale]?.host || '';
        }
        let urlRedirect = `${origin}/${thisLocale}/${LOCALE_LINKS[thisLocale]?.businessRoot}/access`;

        return {
            id: thisLocale,
            label: getLocalePrettyName(thisLocale),
            urlRedirect,
        };
    });

    const getDefaultLocaleOption = () => dropdownOptions.find((option) => option.id === locale);

    if (!footerItems || footerItems.length < 1) {
        return <></>;
    }

    return (
        <div className={styles.articleFooterContainer}>
            <div className={styles.articleFooterInner}>
                <div className={styles.footerTop}>
                    {Object.values(footerItems[0])?.map((item: any, index: number) => {
                        return (
                            <Fragment key={index}>
                                {renderSingleBlock(Object.values(item).map((item: any) => ({ title: item.name, url: item.link, isLead: item.isLead })))}
                            </Fragment>
                        );
                    })}
                    <div className={styles.footerLocation}>
                        <div className={styles.footerTitle}>Location</div>
                        <Dropdown defaultValue={getDefaultLocaleOption()} dropdownData={dropdownOptions} onChange={handleOnLocationChange} />
                    </div>
                </div>

                <div className={styles.footerBottom}>
                    {Object.values(footerItems[1])?.map((item: any, index: number) => {
                        return (
                            <Fragment key={index}>
                                {renderSingleBlock(Object.values(item).map((item: any) => ({ title: item.name, url: item.link, isLead: item.isLead })))}
                            </Fragment>
                        );
                    })}
                </div>
                <a href={LOCALE_LINKS[locale]?.home} target="_self" className={styles.logoAfterPay}>
                    {locale === Locale.GreatBritain ? <Icon key={Math.random()} type={IconType.LogoClearpay} /> : <Icon key={Math.random()} type={IconType.AfterPay} />}
                </a>
            </div>
        </div>
    );
};

export default BusinessFooter;
