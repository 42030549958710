import React from 'react';

interface Props {}

const LinkedInFilled = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.67055 3.07418C3.11772 2.65328 3.70143 2.44263 4.42183 2.44263C5.14227 2.44263 5.71973 2.6531 6.15359 3.07418C6.58748 3.49517 6.80899 4.02671 6.81766 4.66917C6.82628 5.30287 6.60714 5.83236 6.16015 6.25759C5.71317 6.68305 5.12498 6.89562 4.39574 6.89562H4.3697C3.65792 6.89562 3.08493 6.68287 2.65103 6.25759C2.21699 5.83236 2 5.30268 2 4.66917C2 4.02667 2.22342 3.49517 2.67055 3.07418ZM20.6458 9.83223C19.7432 8.84696 18.5494 8.35423 17.0651 8.35423C16.5183 8.35423 16.0212 8.4215 15.574 8.55613C15.127 8.69067 14.7495 8.87943 14.4413 9.12251C14.1332 9.36559 13.8878 9.59125 13.7057 9.79949C13.5325 9.99728 13.3631 10.2277 13.1978 10.4883V8.6535H8.91379L8.92692 9.27858C8.93572 9.69537 8.94006 10.98 8.94006 13.1327C8.94006 15.2855 8.93139 18.0936 8.91397 21.5571H13.1978V14.3565C13.1978 13.9139 13.2453 13.5624 13.341 13.3018C13.5234 12.8588 13.7987 12.488 14.1679 12.1887C14.5369 11.8889 14.9947 11.7392 15.5417 11.7392C16.288 11.7392 16.8373 11.9974 17.1889 12.5139C17.5404 13.0304 17.7162 13.7444 17.7162 14.6559V21.5567H22V14.1614C21.9997 12.2602 21.5486 10.8172 20.6458 9.83223ZM6.54414 8.65359H2.24731V21.557H6.54414V8.65359Z"
                fill="#141414"
            />
        </svg>
    );
};
export default LinkedInFilled;
