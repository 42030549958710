import React from 'react';

interface Props {}

const ArrowRightSmall = (props: Props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 6H0.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 2L11.5 6L7.5 10" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default ArrowRightSmall;
