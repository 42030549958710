import React from 'react';

interface Props {}

 const ArrowRightOutline = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 12.5H14.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M14.5 17.5V7.5L21.5 12.5L14.5 17.5Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default ArrowRightOutline;
