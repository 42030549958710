import React from 'react';

interface Props {}

const PeopleCircleOutline = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4996 20.9859V20.2409C19.4996 19.1639 18.9226 18.1699 17.9876 17.6359L14.7686 15.7939" stroke="black" strokeMiterlimit="10" />
            <path d="M10.227 15.792L7.012 17.636C6.077 18.17 5.5 19.164 5.5 20.241V20.986" stroke="black" strokeMiterlimit="10" />
            <path
                d="M12.5 16.5C10.291 16.5 8.5 14.709 8.5 12.5V10.5C8.5 8.291 10.291 6.5 12.5 6.5C14.709 6.5 16.5 8.291 16.5 10.5V12.5C16.5 14.709 14.709 16.5 12.5 16.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M12.5 23.5C18.5751 23.5 23.5 18.5751 23.5 12.5C23.5 6.42487 18.5751 1.5 12.5 1.5C6.42487 1.5 1.5 6.42487 1.5 12.5C1.5 18.5751 6.42487 23.5 12.5 23.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
export default PeopleCircleOutline;
