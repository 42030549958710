import React from 'react';

interface Props {}

const CreditCardFilled = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 5V3C16 2.4 15.6 2 15 2H1C0.4 2 0 2.4 0 3V5H16Z" fill="black" />
            <path d="M0 7V13C0 13.6 0.4 14 1 14H15C15.6 14 16 13.6 16 13V7H0ZM6 11H2V10H6V11ZM14 11H12V10H14V11Z" fill="black" />
        </svg>
    );
};
export default CreditCardFilled;
