interface Props {}

const LogoClearpay = (props: Props) => {
    return (
        <svg width="121" height="24" viewBox="0 0 105 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <pattern id="logo-clearpay" viewBox="0 0 105 21" width="100%" height="100%">
                    <path d="M82.9458 4.64771L75.8063 19.8148H72.8483L75.5158 14.1487L71.3253 4.64771H74.3625L77.0564 11.0119L79.9967 4.64771H82.9458Z" fill="white" />
                    <path
                        d="M55.7784 10.0328C55.7784 8.29216 54.5019 6.95041 52.9349 6.95041C51.3679 6.95041 50.0914 8.24683 50.0914 10.0328C50.0914 11.8006 51.3679 13.1152 52.9349 13.1152C54.5019 13.1152 55.7784 11.7734 55.7784 10.0328ZM47.4944 19.8148V4.64769H50.065V6.04383C50.8397 5.05565 51.9929 4.44824 53.3663 4.44824C56.1922 4.44824 58.4106 6.84162 58.4106 10.0056C58.4106 13.1696 56.1482 15.5811 53.3046 15.5811C51.9841 15.5811 50.9013 15.0462 50.1442 14.1396V19.8058H47.4944V19.8148Z"
                        fill="white"
                    />
                    <path
                        d="M67.6896 10.0328C67.6896 8.2196 66.4131 6.95038 64.8461 6.95038C63.2791 6.95038 62.0026 8.2468 62.0026 10.0328C62.0026 11.8006 63.2791 13.1152 64.8461 13.1152C66.4131 13.1152 67.6896 11.8369 67.6896 10.0328ZM67.7072 15.4179V14.0217C66.9325 14.9918 65.7793 15.5901 64.4059 15.5901C61.536 15.5901 59.3616 13.2239 59.3616 10.0328C59.3616 6.86879 61.6241 4.45728 64.4676 4.45728C65.8057 4.45728 66.9325 5.06469 67.7072 6.00753V4.64766H70.2778V15.4179H67.7072Z"
                        fill="white"
                    />
                    <path
                        d="M42.855 5.7003C42.855 5.7003 43.5065 4.44922 45.1175 4.44922C45.8042 4.44922 46.2444 4.694 46.2444 4.694V7.44094C46.2444 7.44094 45.276 6.82447 44.3868 6.95139C43.4977 7.07831 42.9343 7.91237 42.9343 9.03653V15.4189H40.2756V4.64867H42.8462V5.7003H42.855Z"
                        fill="white"
                    />
                    <path
                        d="M102.912 4.04039L99.866 2.24535L96.776 0.423117C94.7336 -0.782639 92.1806 0.731354 92.1806 3.161V3.56896C92.1806 3.79561 92.2951 4.00412 92.4888 4.11291L93.9237 4.95604C94.3199 5.19175 94.8129 4.89258 94.8129 4.42115V3.45111C94.8129 2.97062 95.3147 2.67144 95.7196 2.90716L98.5367 4.57527L101.345 6.23432C101.75 6.47003 101.75 7.07744 101.345 7.31315L98.5367 8.9722L95.7196 10.6403C95.3147 10.876 94.8129 10.5769 94.8129 10.0964V9.61588C94.8129 7.18623 92.2599 5.66317 90.2175 6.87799L87.1275 8.70022L84.0815 10.4953C82.0303 11.7101 82.0303 14.7653 84.0815 15.9801L87.1275 17.7751L90.2175 19.5974C92.2599 20.8031 94.8129 19.2891 94.8129 16.8595V16.4515C94.8129 16.2249 94.6984 16.0164 94.5047 15.9076L93.0698 15.0644C92.6736 14.8287 92.1806 15.1279 92.1806 15.5993V16.5694C92.1806 17.0499 91.6789 17.349 91.2739 17.1133L88.4568 15.4452L85.6485 13.7862C85.2435 13.5504 85.2435 12.943 85.6485 12.7073L88.4568 11.0483L91.2739 9.38016C91.6789 9.14445 92.1806 9.44362 92.1806 9.92411V10.4046C92.1806 12.8342 94.7336 14.3573 96.776 13.1425L99.866 11.3203L102.912 9.52522C104.963 8.30133 104.963 5.25521 102.912 4.04039Z"
                        fill="white"
                    />
                    <path
                        d="M10.7226 11.402C10.1592 13.877 8.09035 15.5813 5.41411 15.5813C2.3241 15.5813 0 13.188 0 10.024C0 6.86 2.35932 4.44849 5.45812 4.44849C8.07274 4.44849 10.1151 6.15286 10.705 8.58251H7.98471C7.50052 7.61246 6.54095 6.96879 5.47573 6.96879C3.92633 6.96879 2.63222 8.28334 2.63222 10.024C2.63222 11.7646 3.92633 13.0792 5.47573 13.0792C6.58496 13.0792 7.54454 12.4355 8.00231 11.402H10.7226Z"
                        fill="white"
                    />
                    <path d="M11.9462 15.4089V0.2146H14.5784V15.4089H11.9462Z" fill="white" />
                    <path
                        d="M18.54 10.7401C18.7249 12.3357 19.8341 13.2332 21.2339 13.2332C22.3431 13.2332 23.197 12.6984 23.6988 11.8371H26.3927C25.7676 14.1217 23.778 15.5813 21.1634 15.5813C18.003 15.5813 15.7933 13.2967 15.7933 10.0511C15.7933 6.80557 18.135 4.45752 21.225 4.45752C24.3415 4.45752 26.5951 6.8237 26.5951 10.0511C26.5951 10.2869 26.5775 10.5226 26.5335 10.7401H18.54ZM23.7868 9.0539C23.602 7.65776 22.4927 6.81464 21.1986 6.81464C19.9045 6.81464 18.8393 7.63056 18.5664 9.0539H23.7868Z"
                        fill="white"
                    />
                    <path
                        d="M35.9972 15.4091V14.0129C35.2225 14.983 34.0781 15.5813 32.696 15.5813C29.8348 15.5813 27.6604 13.2152 27.6604 10.024C27.6604 6.86 29.9141 4.44849 32.7576 4.44849C34.0957 4.44849 35.2225 5.04683 35.9972 5.99875V4.63887H38.5678V15.4H35.9972V15.4091ZM35.9708 10.024C35.9708 8.21987 34.6943 6.94159 33.1273 6.94159C31.5603 6.94159 30.2838 8.22894 30.2838 10.024C30.2838 11.7918 31.5603 13.1064 33.1273 13.1064C34.6943 13.1064 35.9708 11.8371 35.9708 10.024Z"
                        fill="white"
                    />
                </pattern>
            </defs>
            <rect  fill='url(#logo-clearpay)' width="100%" height="100%" />
        </svg>
    );
};

export default LogoClearpay;
