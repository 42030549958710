import React from 'react';

interface Props {}

const Subscribed = (props: Props) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="black" />
            <path
                d="M32.6036 16.5622L19.9918 29.1657L15.3964 24.5733C15.0184 24.2085 14.5122 24.0066 13.9867 24.0112C13.4612 24.0158 12.9586 24.2264 12.587 24.5977C12.2154 24.9691 12.0046 25.4714 12.0001 25.9965C11.9955 26.5216 12.1975 27.0275 12.5626 27.4053L18.5749 33.4136C18.9507 33.7891 19.4604 34 19.9918 34C20.5232 34 21.0329 33.7891 21.4087 33.4136L35.4374 19.3941C35.8025 19.0164 36.0045 18.5105 35.9999 17.9854C35.9954 17.4603 35.7846 16.9579 35.413 16.5866C35.0414 16.2153 34.5388 16.0046 34.0133 16.0001C33.4878 15.9955 32.9816 16.1974 32.6036 16.5622Z"
                fill="white"
            />
        </svg>
    );
};

export default Subscribed;
