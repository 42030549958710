import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getResizedBuilderImage } from '../../helpers/BuilderHelper';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import { Size, useWindowSize } from '../../hook/useWindowSize';
import { Article } from '../../types/Article';
import CategoriesComponent from '../categories/categories';
import Icon, { IconType } from '../icons';
import { LazyBackgroundImage } from '../lazy-background-image/lazy-background-image';
import ArticleCardSkeleton from '../skeleton-load/article-card-skeleton/article-card-skeleton';
import styles from './article-card.module.scss';

export enum ArticleCardType {
    Small = 'small',
    Large = 'large',
}
interface Props {
    article: Article;
    type: ArticleCardType;
    fixedWidth?: boolean;
}

const ArticleCard: React.FC<Props> = (props: Props) => {
    const { locale } = useLocale();

    const [isLoading, setLoading] = useState<boolean>(false);
    const { article, type, fixedWidth } = props;

    const windowSize: Size = useWindowSize();
    const getClassSkeletonImage = () => {
        if (isLoading) {
            return 'loading';
        } else {
            return '';
        }
    };

    const targetUrl = localifyLink(`/${locale}${article.articleUrl}`, locale);

    const articleImage = useMemo(() => {
        // Horiz image for small card
        let articleImage = article.leadImage || article.leadPortraitImage;
        let imageWidth = 500;
        if ((windowSize.width ?? 0) > 768 && type === ArticleCardType.Large) {
            // Portrait image for Large card
            articleImage = article.leadPortraitImage || article.leadImage;
            imageWidth = 800;
        }

        return { url: articleImage, width: imageWidth };
    }, [type, windowSize.width, article]);

    return isLoading || !article ? (
        <ArticleCardSkeleton {...{ type }} />
    ) : (
        <div className={classNames(styles.articleCard, styles[type], { [styles.fixedWidth]: fixedWidth })} style={{ position: 'relative' }}>
            <div className={styles.articleCardImageWrapper + ' ' + styles[getClassSkeletonImage()]}>
                <LazyBackgroundImage className={styles.articleCardImage} imageUrl={articleImage.url} imageWidth={articleImage.width} />
            </div>
            <div className={styles.articleCardContentWrapper}>
                <div className={styles.categoriesList}>
                    <CategoriesComponent type="SMALL" categories={article.categories} />
                </div>
                <div className={styles.articleCardContent}>
                    <div className={`${styles.articleCardContentTitle} ${styles.threeLinesLimit}`}>{article.title}</div>
                    <div className={`${styles.articleCardContentDescription} ${styles.threeLinesLimit}`}>{article.description}</div>
                </div>

                <a href={targetUrl} target="_self" className={styles.articleCardButtonReadMore}>
                    <span className={styles.businessButtonText}>Read more</span>
                    <Icon type={IconType.ArrowRightMedium} />
                </a>
            </div>
            <a href={targetUrl} target="_self" className={styles.articleCardLink} />
        </div>
    );
};

export default ArticleCard;
