import React from 'react';
import { replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import { Category } from '../../types/Category';
import styles from './categories.module.scss';
interface Props {
    categories: Category[];
    type: 'LARGE' | 'SMALL';
    color?: 'grey' | 'white';
}

const CategoriesComponent: React.FC<Props> = (props: Props) => {
    const { locale } = useLocale();

    const { categories, type, color = 'grey' } = props;

    return (
        <div className={styles.categoriesList + ' ' + styles[type]}>
            {(categories || []).map((item: Category, index: number) => {
                const url = replaceOrAddLocale(item.categoryUrl ?? '', locale);

                return (
                    <a key={index} className={styles.categoryName + ' ' + styles[color]} href={url}>
                        {item.name}
                    </a>
                );
            })}
        </div>
    );
};

export default CategoriesComponent;
