import React from 'react';

const Copy = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 2H2.5V8.5C2.5 8.775 2.275 9 2 9C1.725 9 1.5 8.775 1.5 8.5V2C1.5 1.45 1.95 1 2.5 1H7.5C7.775 1 8 1.225 8 1.5C8 1.775 7.775 2 7.5 2ZM10 4V10C10 10.55 9.55 11 9 11H4.5C3.95 11 3.5 10.55 3.5 10V4C3.5 3.45 3.95 3 4.5 3H9C9.55 3 10 3.45 10 4ZM9 4H4.5V10H9V4Z"
                fill="black"
            />
        </svg>
    );
};

export default Copy;
