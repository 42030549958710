import React, { useEffect, useState } from 'react';
import { Category } from '../../types/Category';
import Icon, { IconType } from '../icons';
import styles from './dropdown-explore.module.scss';
import { ALL_LOCALES, Locale, localifyLink, useLocale } from '../../helpers/LangHelper';

interface DummyResourceProp {
    name: string;
    description: string;
    resourceUrl: string;
    locale: Locale[];
}
interface GuideProp {
    name: string;
    guideUrl: string;
}
interface Props {
    isToggle: boolean;
    categories: Category[];
    guides?: GuideProp[];
    resources?: DummyResourceProp[];
}

const dummyResources = [
    { name: 'Marketing Resources', description: '', resourceUrl: '/business/resources/marketing', locale: ALL_LOCALES },
    {
        name: 'Training',
        description: '',
        resourceUrl: '/business/resources/training',
        locale: ALL_LOCALES.filter((location) => location !== Locale.GreatBritain && location !== Locale.USA),
    },
    { name: 'Product Updates', description: '', resourceUrl: '/business/resources/product-updates', locale: ALL_LOCALES },
    { name: 'Customer Service', description: '', resourceUrl: '/business/resources/customer-service', locale: ALL_LOCALES.filter((location) => [Locale.USA, Locale.GreatBritain].includes(location)) },
];

const GUIDE_ORDER = ['Meet Gen Z & Millennials', 'Growing with Afterpay', 'Social Media Tips', 'Content Marketing', 'SEO Tips'];

const DropdownExplore = (props: Props) => {
    const { locale } = useLocale();
    const [sortedGuides, setGuides] = useState<GuideProp[]>([]);

    const { isToggle, categories, guides } = props;

    useEffect(() => {
        // You're welcome
        const dummyGuides = [
            { name: 'Meet Gen Z & Millenials', guideUrl: '/business/access/meet-gen-z---millennials' },
            {
                name: `Growing with ${locale === Locale.GreatBritain ? 'Clearpay' : 'Afterpay'}`,
                guideUrl: `/business/access/growing-with-${locale === Locale.GreatBritain ? 'clearpay' : 'afterpay'}`,
            },
            { name: 'Social Media Tips', guideUrl: '/business/access/social-media-tips' },
            { name: 'Content Marketing ', guideUrl: '/business/access/content-marketing' },
            { name: 'SEO Tips', guideUrl: '/business/access/seo-tips' },
        ];

        setGuides(dummyGuides);
        return;
    }, [guides]);

    if (!categories) {
        return null;
    }

    const accessHref = localifyLink(`/${locale}/business/access`, locale);
    const guidePageHref = localifyLink(`/${locale}/business/access/guides`, locale);
    //TODO make these business resource pages come from a generalized location.

    const renderCategories = () => {
        return (
            <ul className={styles.latestArticlesListWrapper}>
                <a className={styles.articleTypeTitle} href={accessHref}>
                    Topics
                </a>
                {categories.map((category: Category) => {
                    return (
                        <li key={category.name}>
                            <a href={localifyLink(`/${locale}${category.categoryUrl}`, locale)} className={styles.articleTypeName}>
                                {category.name}
                            </a>
                        </li>
                    );
                })}
                <div className={styles.exploreButtonWrapper}>
                    <span className={styles.exploreButtonText}>View all articles</span>
                    <Icon type={IconType.ArrowRightMedium} />
                    <a href={accessHref} target="_self"></a>
                </div>
            </ul>
        );
    };
    const renderGuides = () => {
        return (
            <ul className={styles.latestArticlesListWrapper}>
                <a className={styles.articleTypeTitle} href={guidePageHref}>
                    Guides
                </a>
                {sortedGuides.slice(0, 5).map((series: GuideProp) => {
                    return (
                        <li key={series.name}>
                            <a href={localifyLink(`/${locale}${series.guideUrl}`, locale)} className={styles.articleTypeName}>
                                {series.name}
                            </a>
                        </li>
                    );
                })}
                <div className={styles.exploreButtonWrapper}>
                    <span className={styles.exploreButtonText}>View all guides</span>
                    <Icon type={IconType.ArrowRightMedium} />
                    <a href={guidePageHref} target="_self"></a>
                </div>
            </ul>
        );
    };
    const renderMoreResources = () => {
        return (
            <div className={styles.moreResourceWrapper}>
                {dummyResources.map((resource: DummyResourceProp) => {
                    if (!resource.locale.includes(locale)) return null;
                    return (
                        <a key={resource.name} className={styles.moreResourceContainer} href={localifyLink(`/${locale}${resource.resourceUrl}`, locale)}>
                            <div>
                                <div className={styles.moreResourceName}>{resource.name}</div>
                                {resource.description ? <div className={styles.moreResourceDescription}>{resource.description}</div> : null}
                            </div>
                            <div className={styles.moreResourceViewMoreIcon}>
                                <Icon type={IconType.ArrowRightMedium} />
                            </div>
                        </a>
                    );
                })}
            </div>
        );
    };
    return (
        <div className={styles.dropdownExplore + ' ' + (isToggle ? styles.toggleExploreOn : '')}>
            <div className={styles.dropdownContainer}>
                <div className={styles.latestArticles}>
                    <a href={localifyLink(`/${locale}/business/access`, locale)}>
                        <div className={styles.dropdownTitle}>
                            <Icon type={IconType.ReadBook} />
                            {locale === Locale.GreatBritain ? 'Clearpay Access' : 'Afterpay Access'}
                            {/* TODO replace link with a dynamic local selecting link */}
                        </div>
                    </a>
                    <div className={styles.latestArticlesContainer}>
                        {renderCategories()}
                        {renderGuides()}
                    </div>
                </div>
                <div className={styles.moreResource}>
                    <a href={localifyLink(`/${locale}/business/resources/`, locale)}>
                        <div className={styles.dropdownTitle}>
                            <Icon type={IconType.Resource} />
                            More Resources
                        </div>
                    </a>
                    {renderMoreResources()}
                </div>
            </div>
        </div>
    );
};

export default DropdownExplore;
