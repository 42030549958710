import React from 'react';

interface Props {}

const Support = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7781 4.22217L15.5361 8.46417" stroke="black" strokeMiterlimit="10" />
            <path d="M19.7781 19.7779L15.5361 15.5359" stroke="black" strokeMiterlimit="10" />
            <path d="M4.22168 19.7779L8.46368 15.5359" stroke="black" strokeMiterlimit="10" />
            <path d="M4.22168 4.22217L8.46368 8.46417" stroke="black" strokeMiterlimit="10" />
            <path
                d="M19.7775 19.778C24.0732 15.4823 24.0732 8.51752 19.7775 4.22179C15.4817 -0.0739315 8.51704 -0.0739315 4.22131 4.22179C-0.07442 8.51752 -0.07442 15.4823 4.22131 19.778C8.51704 24.0737 15.4817 24.0737 19.7775 19.778Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M15.535 15.5354C17.4876 13.5828 17.4876 10.4171 15.535 8.46445C13.5824 6.51185 10.4166 6.51185 8.46396 8.46445C6.51136 10.4171 6.51136 13.5828 8.46396 15.5354C10.4166 17.488 13.5824 17.488 15.535 15.5354Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
export default Support;
