import React from 'react';

interface Props {}

const Chat = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17H4C2.3 17 1 18.3 1 20V23H13V20C13 18.3 11.7 17 10 17Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M7 14C5.3 14 4 12.7 4 11V10C4 8.3 5.3 7 7 7C8.7 7 10 8.3 10 10V11C10 12.7 8.7 14 7 14Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M13 1V11L17 8H23V1H13Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default Chat;
