import React from 'react';

const PenTool = () => {
    return (
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9728 20.6484L6.2002 22.6667L8.13635 15.613L22.1637 1L27.0002 6.03951L12.9728 20.6484Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.1333 3.6001H1V27.8668H25.2667V15.7334" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default PenTool;
