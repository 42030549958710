import React from 'react';

interface Props {}

const Check = (props: Props) => {
    return (
        <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.70163L9.52579 17L26 1" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default Check;
