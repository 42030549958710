import React, { useState } from 'react';
import { Size, useWindowSize } from '../../../hook/useWindowSize';
import { ArticleCardType } from '../../article-card/article-card';
import ArticleCardSkeleton from '../article-card-skeleton/article-card-skeleton';
import styles from './article-grid-skeleton.module.scss';
interface Props {}
interface ArticleGridSkeletonItem {
    type: ArticleCardType;
}
const dummyArticleGridSkeleton: ArticleGridSkeletonItem[] = [
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Large },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Large },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Small },
    { type: ArticleCardType.Small },
];
const ArticleGridSkeleton = (props: Props) => {
    return (
        <div className={styles.articleGridSkeleton}>
            {dummyArticleGridSkeleton.map((articleSkeleton, index: number) => {
                return <ArticleCardSkeleton key={`article_skeleton_${index}`} type={articleSkeleton.type} />;
            })}
        </div>
    );
};

export default ArticleGridSkeleton;
