import builder from '@builder.io/react';
import { GetContentOptions } from '@builder.io/sdk';
import algoliasearch from 'algoliasearch';
import { BUILDER_API_KEY, ALGOLIA_API_KEY, ALGOLIA_APP_ID } from '../shared/env';
import { Article } from '../types/Article';
import { Category } from '../types/Category';
import { ALL_LOCALES, BusinessUrlPrefix, Locale, LOCALE_LINKS } from './LangHelper';
import { COLORS } from '../types/Resource';

builder.init(BUILDER_API_KEY);

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const articleIndex = client.initIndex('builder-article');

const CATEGORY_ROOT = '/business/access/';

/**
 * https://dijitally.atlassian.net/browse/APB-212
 * https://dijitally.atlassian.net/browse/APB-257
 */
export const excludedArticleIds = [
    '03c8d98acf1341a1a78668a42f76f342',
    '6500a1b582874d038178ffca459b35b4',
    '9b182828368a49a6b0872ec7119b36c0',
    'e7d6577785ca4e35ae3a13f0ba811e2d',
    '9647e35f141a4d73aac1367e9093c6ce',
    '98980189fa414d4b8042b099ef9be739',
    'd258b5ff845a46ce8da8b549c5774023',
    '3813f56a18a44646bb94543fd3684ba7',
    'fcd0925629d44c73b60ac65b8d9196af',
    '3d5f6f728a8e480fae7507f2f82a64d4',
    '74fbbf2c675d44479f0df5ff6a95e7b1',
    'a1a6b180b0c440c99b9ad86d925db1c6',
    'e3d02593db2f4cd09c9caf6bd61cd8dc',
    'a0c28c5496ba4cefb7539ed335075a84',
    '957e67c8b8f54c95b1eed46e2615bc55',
    'c4d5b594468f4b63b9a0d36e9a236434',
    'e1f67ee797944b449e8db3b49b125558',
    '87ca4620882d4b04937f7921724c8c87',
    '1cc3c604bd514519b8f4a6bb2c1ab200',
    '237989ea7b774f20987ff8003ef88cc1',
    '4c2ee6ee27e84417a4e650e78bf6cc37',
];

export function mapBuilderObjectToArticle(object: any): Article {
    const oData = object.data;

    const categories: Category[] = oData.categories?.map((category: any) => mapBuilderObjectToCategory(category.category.value));
    const author = oData?.author?.value?.data;

    const articleSlug = oData.url?.replace('/builder/', '') ?? '';

    const articleUrl = categories && categories.length ? `${categories[0].categoryUrl}/${articleSlug}`.replace(/\/{2,}/, '/') : null;

    return {
        title: oData.title ?? '',
        description: oData.description ?? '',
        categories: categories ?? null,
        author: author ?? null,
        contributors: oData.contributors ?? [],

        leadImage: oData.leadImage ?? null,
        leadPortraitImage: oData.leadPortraitImage ?? null,

        url: articleUrl ?? '',
        articleUrl: articleUrl ?? '',
        articleSlug,

        priority: oData.priority ?? 0,

        builderModel: object ?? null,

        seoTitle: oData.seoTitle ?? oData.title ?? '',
        seoDescription: oData.seoDescription ?? oData.description ?? '',

        leadVideo: oData.leadVideo ?? null,

        lastUpdated: object.lastUpdated ?? null,
    };
}

export function mapBuilderObjectToCategory(item: any): Category {
    if (!item) {
        return {
            id: '',
            name: '',
            longName: null,

            description: '',
            categoryUrl: '',
            isSeries: false,
            image: '',
            lastUpdated: '',
            exclusiveForLocales: [],
        };
    }

    let name;
    let category = item;

    if (item.data === undefined) {
        name = item.name ?? '';
    } else {
        category = item.data;
        name = item.name ?? item.data.name;
    }

    const categorySlug = categoryNameToUrlSlug(name);

    // TODO generalize this parser here
    return {
        id: item.id,
        name,
        longName: category.longName ?? null,

        categoryUrl: (CATEGORY_ROOT + categorySlug).replace(/\/{2,}/g, '/'),

        isSeries: !!category.isSeries || false,
        categorySlug,
        description: category.description || '',
        image: category.image || '',
        lastUpdated: item.lastUpdated || '',
        exclusiveForLocales: category.exclusiveForLocales ?? [],
    };
}

const categoryNameToUrlSlug = (name: string): string => {
    return name
        .trim()
        .toLowerCase()
        .replace(/[^A-z]/g, '-')
        .trimEnd();
};

export const getAllArticles = async (
    locale?: Locale | Locale[],
    filters?: Partial<{ categoryIds: string[]; offset: number; limit: number; extra?: any }>,
    sort?: any,
    includeUnpublished = false,
    limitFields?: string
): Promise<Article[]> => {
    const getConfig: GetContentOptions = {
        query: {},
        options: {
            noTargeting: true,
            includeUnpublished,
        },
    };

    if (filters?.extra) {
        getConfig.query = filters?.extra;
    }

    if (locale) {
        getConfig.query.query = {
            $elemMatch: {
                property: {
                    $eq: 'locale',
                },
                value: locale,
            },
        };
    }

    if (filters?.categoryIds?.length) {
        getConfig.query['data.categories'] = {
            $elemMatch: {
                'category.id': {
                    $in: filters.categoryIds,
                },
            },
        };
    }

    if (sort && getConfig.options) {
        getConfig.options.sort = sort;
    }

    if (limitFields) {
        getConfig.fields = limitFields;
    }

    getConfig.limit = filters?.limit;

    if (getConfig.options) {
        if (typeof filters?.offset === 'string') {
            getConfig.options.offset = parseInt(filters?.offset ?? '0');
        } else {
            getConfig.options.offset = filters?.offset;
        }
    }

    const articles = await builder.getAll('article', getConfig);

    return articles.map((page) => {
        return mapBuilderObjectToArticle(page);
    });
};

const getArticlePage = async (params: { url?: string; modelId?: string; locale?: Locale }, includeUnpublished = false) => {
    return getBuilderPage('article', params, includeUnpublished);
};

export const getBuilderPage = async (model: string, params: { url?: string; modelId?: string; locale?: Locale }, includeUnpublished = false) => {
    const query: any = {
        userAttributes: {},

        options: {
            includeUnpublished,
            noTargeting: false,
        },
    };

    if (params.url) {
        query.userAttributes.urlPath = params.url;

        if (params.locale) {
            query.userAttributes.locale = params.locale;
        } else {
            query.userAttributes.locale = ALL_LOCALES;
        }
    }

    if (params.modelId) {
        query.entry = params.modelId;
    }

    // TODO replace these string entries with constants
    const article = (await builder.get(model, query).toPromise()) || null;

    return article;
};

export const getArticlePageFromUrl = async (path = '', locale?: Locale, includeUnpublished = false) => {
    return getArticlePage({ url: '/builder/' + path, locale }, includeUnpublished);
};

export const getArticleFromID = async (modelId: string, includeUnpublished = false) => {
    return getArticlePage({ modelId }, includeUnpublished);
};

export const getCategoryFromName = async (name: string) => {
    const slug = categoryNameToUrlSlug(name);

    const allPossibleCategories = await getAllCategories();
    for (const category of allPossibleCategories) {
        if (`${CATEGORY_ROOT}${slug}` === category.categoryUrl) {
            // TODO refactor check to come from a generic place
            return category;
        }
    }

    return null;
};

export const getCategoryFromID = async (modelId: string) => {
    const foundCategory = await getBuilderPage('category', { modelId }, true);

    if (foundCategory) {
        return mapBuilderObjectToCategory(foundCategory);
    }

    return null;
};

export const getArticlesByCategory = async ({ locale, offset, limit = 9, categoryIds = [] }: { locale: Locale; offset?: number; limit?: number; categoryIds?: string[] }) => {
    return await getAllArticles(locale, {
        categoryIds,
        limit,
        offset,
        extra: {
            id: {
                $nin: excludedArticleIds,
            },
        },
    });
};
export const getRelatedArticles = async (article: Article, locale?: Locale): Promise<Article[]> => {
    if (!article) {
        return [];
    }

    const categoryIds = article.categories
        ? [...article.categories].map((item: Category) => {
              return item.id;
          })
        : [];

    const config: GetContentOptions = {
        options: { noTargeting: true },
        query: {
            id: {
                $nin: excludedArticleIds.concat([article.builderModel.id]),
            },
            'data.categories': {
                $elemMatch: {
                    'category.id': {
                        $in: [categoryIds[0]],
                    },
                },
            },
        },
        limit: 3,
    };
    if (locale) {
        config.query.query = {
            $elemMatch: {
                property: {
                    $eq: 'locale',
                },
                value: locale,
            },
        };
    }

    const relatedPages = await builder.getAll('article', config);
    const relatedArticles: Article[] = relatedPages.map((page) => {
        return mapBuilderObjectToArticle(page);
    });

    return relatedArticles;
};

export const getLatestArticles = async ({ locale, offset, limit = 4, categoryIds = [] }: { locale: Locale; offset?: number; limit?: number; categoryIds?: string[] }) => {
    return getAllArticles(
        locale,
        {
            extra: {
                data: {
                    'leadVideo.videoUrl': {
                        $exists: false,
                    },
                },
            },
            categoryIds,
            offset,
            limit,
        },
        { 'data.priority': -1, lastUpdated: -1 }
    );
};

let cachedCategoryList: Category[] = [];
export const getAllCategories = async () => {
    // TODO add caching mechanism to avoid loading this over and over again

    if (cachedCategoryList.length !== 0) {
        return cachedCategoryList;
    }

    const allCategory = await builder.getAll('category', { options: { noTargeting: true, includeUnpublished: false } });
    const categoriesData: Category[] = allCategory.map((item: any) => mapBuilderObjectToCategory(item));

    if (cachedCategoryList.length === 0) {
        cachedCategoryList = categoriesData;
    }
    return categoriesData;
};

export const getCategoriesFromIDs = async (categoryIds: string[] = []) => {
    const allCategory = await builder.getAll('category', {
        options: { noTargeting: true, includeUnpublished: false },
        query: {
            id: {
                $in: categoryIds,
            },
        },
    });
    const categoriesData: Category[] = allCategory.map((item: any) => mapBuilderObjectToCategory(item));

    return categoriesData;
};

export const getPageFromPageID = async (id: string) => {
    return getBuilderPage('page', { modelId: id }, true);
};

export const getArticleSearchResults = async ({
    locale,
    keyword,
    limit = 25,
    offset = 0,
    onlyFields,
}: {
    locale: Locale;
    keyword: string;
    limit?: number;
    offset?: number;
    onlyFields?: string;
}) => {
    try {
        const { hits: articles } = await articleIndex.search<any>(keyword, {
            hitsPerPage: limit,
            offset: offset,
            attributesToRetrieve: ['*'],
            removeWordsIfNoResults: 'none',
            exactOnSingleWordQuery: 'word',
            facets: ['published', 'query.value'],
            facetFilters: [[`query.value:${locale}`], ['published:published']],
        });

        const ids = articles.map((article) => article.objectID);
        return getAllArticles(
            locale,
            {
                extra: {
                    id: {
                        $in: ids,
                    },
                },
                limit,
                offset,
            },
            undefined,
            false,
            onlyFields
        );
    } catch (ex) {
        console.error(ex);
        return getAllArticles(
            locale,
            {
                extra: {
                    $or: [
                        {
                            name: {
                                $regex: keyword,
                                $options: 'i',
                            },
                        },
                        {
                            description: {
                                $regex: keyword,
                                $options: 'i',
                            },
                        },
                        {
                            'data.title': {
                                $regex: keyword,
                                $options: 'i',
                            },
                        },
                        {
                            'data.blocks.$elemMatch.component.options.text': {
                                $regex: keyword,
                                $options: 'i',
                            },
                        },
                    ],
                },
                limit,
                offset,
            },
            undefined,
            false,
            onlyFields
        );
    }
};

export type QuickSearchSuggestions = {
    categories: QuickSearchSuggestionItem[];
    articles: QuickSearchSuggestionItem[];
};

export type QuickSearchSuggestionItem = {
    name: string;
    id: string;
    link: string;
};

/**
 * Function used for fetching paths for Static Path mapping
 *
 * @returns Promise
 */
export const getAllArticlePaths = async (): Promise<{ lang: Locale; category: string; article: string; business: string }[]> => {
    const pathArray: any[] = [];

    let currentOffset = 0;
    const fetchArticles = async (offset: number) => {
        const currentArticles = await getAllArticles(undefined, { offset, limit: 100 });

        currentArticles.map((page) => {
            const queryFilters = page.builderModel.query;

            // Check if page has lang
            if (queryFilters.length > 0 && queryFilters[1].property == 'locale') {
                queryFilters[1].value.forEach((lang: Locale) => {
                    page.categories.forEach((category: Category) => {
                        if (category.categoryUrl) {
                            const businessPath = LOCALE_LINKS[lang]?.businessRoot;

                            pathArray.push({
                                lang,
                                category: category.categorySlug ?? '',
                                article: page.articleSlug,
                                business: businessPath ?? BusinessUrlPrefix.Business,
                            });
                        }
                    });
                });
            }
        });

        if (currentArticles.length === 100) {
            await fetchArticles((currentOffset += 100));
        }
    };

    await fetchArticles(currentOffset);

    return pathArray;
};

/**
 * Function will return a promise, with suggestions for category, and articles
 */
export const getQuickSearchResponses = async (locale: Locale, query: string): Promise<QuickSearchSuggestions> => {
    const articles: QuickSearchSuggestionItem[] = [];
    const categories: QuickSearchSuggestionItem[] = [];

    const queryPromises: Promise<void>[] = [
        (async () => {
            const foundCategories = await getAllCategories();
            const includesRegex = new RegExp(query, 'i');

            if (foundCategories.length) {
                for (const category of foundCategories) {
                    if (category.exclusiveForLocales?.length && !category.exclusiveForLocales.includes(locale)) {
                        continue;
                    }

                    if (includesRegex.test(category.name)) {
                        categories.push({ name: category.name, link: category.categoryUrl ?? '', id: category.id });

                        if (categories.length >= 5) {
                            break;
                        }
                    }
                }
            }
        })(),
        (async () => {
            const foundArticles = await getArticleSearchResults({
                locale,
                keyword: query,
                limit: 5,
            });

            if (foundArticles.length) {
                foundArticles.forEach((article) => {
                    articles.push({
                        name: article.title,
                        link: article.articleUrl ?? '',
                        id: article.builderModel.id,
                    });
                });
            }
        })(),
    ];

    await Promise.all(queryPromises);

    return {
        categories,
        articles,
    };
};

export const resourcesInput = {
    name: 'resources',
    type: 'list',
    copyOnAdd: false,
    subFields: [
        {
            name: 'type',
            type: 'string',
        },
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'description',
            type: 'richText',
        },
        {
            name: 'description2',
            type: 'richText',
        },
        {
            name: 'assets',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'keyMessages',
                    type: 'Tags',
                },
                {
                    name: 'hide',
                    type: 'boolean',
                },
                {
                    name: 'skipDownload',
                    type: 'boolean',
                },
                // {
                //     name: 'themedAssets',
                //     type: 'Tags',
                // },
                {
                    name: 'variants',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        { name: 'variantName', type: 'string' },
                        {
                            name: 'colors',
                            type: 'list',
                            copyOnAdd: false,
                            subFields: [
                                {
                                    name: 'color',
                                    type: 'string',
                                    enum: Object.values(COLORS),
                                },
                            ],
                        },
                        {
                            name: 'backgroundColor',
                            type: 'color',
                        },
                        {
                            name: 'previewUrl',
                            type: 'url',
                        },
                        {
                            name: 'downloadUrl',
                            type: 'url',
                        },
                        {
                            name: 'fileName',
                            type: 'string',
                        },
                        {
                            name: 'extension',
                            type: 'string',
                        },
                        {
                            name: 'hide',
                            type: 'boolean',
                        },
                    ],
                },
            ],
        },
    ],
};

export type ImageResizeParameters = {
    width?: number;
    height?: number;
    quality?: number;
    format?: 'webp';
};

export const getResizedBuilderImage = (source: string | undefined, parameters?: ImageResizeParameters) => {
    if (source && source.startsWith('https://cdn.builder.io/api/v1/image/')) {
        let queryArray: string[] = [];

        if (parameters) {
            parameters.quality = parameters.quality ?? 85;
            parameters.format = 'webp';

            const parameterKeys = Object.keys(parameters);
            parameterKeys.forEach((paramKey) => {
                const paramValue = parameters[paramKey as keyof ImageResizeParameters];
                if (paramValue) {
                    queryArray.push(`${paramKey}=${paramValue}`);
                }
            });
        }

        return `${source}?${queryArray.join('&')}`;
    }

    return source;
};

export const getPlaceholderBuilderImage = (source: string | undefined) => {
    return getResizedBuilderImage(source, { width: 1, height: 1 });
};
