import React from 'react';

const AfterpayBadge = () => {
    return (
        <svg width="184" height="64" viewBox="0 0 184 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M151.982 64H32.0182C14.3337 64 0 49.6744 0 32C0 14.3256 14.3337 0 32.0182 0H151.982C169.666 0 184 14.3256 184 32C184.012 49.6628 169.666 64 151.982 64Z"
                fill="#B2FCE4"
            />
            <path
                d="M156.833 24.3482L148.724 19.7087C146.025 18.1622 142.651 20.1041 142.651 23.2203V23.7436C142.651 24.0343 142.802 24.3017 143.058 24.4413L144.954 25.5227C145.478 25.825 146.129 25.4413 146.129 24.8366V23.5924C146.129 22.9762 146.793 22.5924 147.328 22.8948L151.051 25.0343L154.762 27.1622C155.297 27.4645 155.297 28.2436 154.762 28.5459L151.051 30.6738L147.328 32.8134C146.793 33.1157 146.129 32.732 146.129 32.1157V31.4994C146.129 28.3831 142.755 26.4296 140.056 27.9878L131.947 32.6273C129.236 34.1855 129.236 38.1041 131.947 39.6622L140.056 44.3017C142.755 45.8482 146.129 43.9064 146.129 40.7901V40.2668C146.129 39.9762 145.978 39.7087 145.722 39.5692L143.826 38.4878C143.302 38.1855 142.651 38.5692 142.651 39.1738V40.418C142.651 41.0343 141.988 41.418 141.452 41.1157L137.729 38.9762L134.018 36.8482C133.483 36.5459 133.483 35.7668 134.018 35.4645L137.729 33.3366L141.452 31.1971C141.988 30.8948 142.651 31.2785 142.651 31.8948V32.511C142.651 35.6273 146.025 37.5808 148.724 36.0227L156.833 31.3831C159.544 29.8134 159.544 25.9064 156.833 24.3482Z"
                fill="black"
            />
            <path d="M130.423 25.1157L120.999 44.5692H117.09L120.615 37.3017L115.065 25.1157H119.079L122.639 33.2785L126.525 25.1157H130.423Z" fill="black" />
            <path
                d="M36.1948 32.0227C36.1948 29.6971 34.5078 28.0692 32.4369 28.0692C30.3659 28.0692 28.6789 29.732 28.6789 32.0227C28.6789 34.2901 30.3659 35.9762 32.4369 35.9762C34.5078 35.9762 36.1948 34.3482 36.1948 32.0227ZM36.2297 38.9296V37.1389C35.2059 38.3831 33.6818 39.1506 31.8668 39.1506C28.0739 39.1506 25.2002 36.1157 25.2002 32.0227C25.2002 27.9645 28.1903 24.8715 31.9482 24.8715C33.7167 24.8715 35.2059 25.6506 36.2297 26.8599V25.1157H39.627V38.9296H36.2297Z"
                fill="black"
            />
            <path
                d="M56.148 35.8599C54.9613 35.8599 54.6239 35.418 54.6239 34.2552V28.1273H56.8112V25.1157H54.6239V21.7436H51.1452V25.1157H46.6543V23.7436C46.6543 22.5808 47.0964 22.1389 48.318 22.1389H49.0859V19.4645H47.4105C44.5368 19.4645 43.1755 20.4064 43.1755 23.2785V25.1273H41.2442V28.1273H43.1755V38.9296H46.6543V28.1273H51.1452V34.8948C51.1452 37.7087 52.2272 38.9296 55.0427 38.9296H56.8345V35.8599H56.148Z"
                fill="black"
            />
            <path
                d="M68.6435 30.7785C68.3992 28.9878 66.9332 27.9064 65.2113 27.9064C63.501 27.9064 62.0816 28.9529 61.7326 30.7785H68.6435ZM61.6977 32.9296C61.942 34.9762 63.408 36.1389 65.2695 36.1389C66.7354 36.1389 67.864 35.4529 68.5272 34.3482H72.099C71.2729 37.2785 68.6435 39.1506 65.1881 39.1506C61.0113 39.1506 58.0794 36.2203 58.0794 32.0459C58.0794 27.8715 61.1741 24.8599 65.2695 24.8599C69.3881 24.8599 72.3782 27.8948 72.3782 32.0459C72.3782 32.3482 72.3549 32.6506 72.2968 32.9296H61.6977Z"
                fill="black"
            />
            <path
                d="M94.5303 32.0227C94.5303 29.7901 92.8433 28.0692 90.7724 28.0692C88.7014 28.0692 87.0144 29.732 87.0144 32.0227C87.0144 34.2901 88.7014 35.9762 90.7724 35.9762C92.8433 35.9762 94.5303 34.2669 94.5303 32.0227ZM83.5822 44.5692V25.1157H86.9795V26.9064C88.0034 25.6389 89.5275 24.8599 91.3425 24.8599C95.0771 24.8599 98.0091 27.9296 98.0091 31.9878C98.0091 36.0459 95.019 39.1389 91.261 39.1389C89.5159 39.1389 88.0848 38.4529 87.0842 37.2901V44.5575H83.5822V44.5692Z"
                fill="black"
            />
            <path
                d="M110.26 32.0227C110.26 29.6971 108.573 28.0692 106.502 28.0692C104.431 28.0692 102.744 29.732 102.744 32.0227C102.744 34.2901 104.431 35.9762 106.502 35.9762C108.573 35.9762 110.26 34.3482 110.26 32.0227ZM110.295 38.9296V37.1389C109.271 38.3831 107.747 39.1506 105.932 39.1506C102.139 39.1506 99.2656 36.1157 99.2656 32.0227C99.2656 27.9645 102.256 24.8715 106.014 24.8715C107.782 24.8715 109.271 25.6506 110.295 26.8599V25.1157H113.692V38.9296H110.295Z"
                fill="black"
            />
            <path
                d="M77.4508 26.4645C77.4508 26.4645 78.3118 24.8599 80.4409 24.8599C81.3484 24.8599 81.9301 25.1738 81.9301 25.1738V28.6971C81.9301 28.6971 80.6503 27.9064 79.4752 28.0692C78.3002 28.232 77.5555 29.3017 77.5555 30.7436V38.918H74.0419V25.1157H77.4392V26.4645H77.4508Z"
                fill="black"
            />
        </svg>
    );
};

export default AfterpayBadge;
