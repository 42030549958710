import classNames from 'classnames';
import React, { Key, useState } from 'react';
import Icon, { IconType } from '../icons';
import styles from './dropdown-selector.module.scss';

export interface DropDown {
    id: Key;
    label: string;
    urlRedirect?: string;
}
interface Props {
    defaultValue?: DropDown;
    dropdownData: DropDown[];
    onChange?: (selectedItem: DropDown | undefined) => void;
}

const Dropdown = (props: Props) => {
    const { defaultValue, dropdownData, onChange } = props;
    const [isDropdownOpen, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Key | null>(defaultValue?.id || dropdownData[0].id);

    if (!dropdownData) {
        return null;
    }

    const handleItemClick = (id: number) => {
        selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
        setOpen(!isDropdownOpen);
        if (onChange) {
            const selectedItem = dropdownData.find(item => item.id === id);
            onChange(selectedItem);
        }
    };
    const classDropdownWrapper = classNames({ 'dropdown-warpper': true, });
    const classDropdownHeader = classNames({
        'dropdown-header': true,
        'dropdown-open': isDropdownOpen,
    });
    const classDropdownBody = classNames({
        'dropdown-body': true,
        'dropdown-open': isDropdownOpen,
    });
    const classIconRight = classNames({
        'icon-right': true,
        'icon-open': isDropdownOpen,
    });

    const renderDefaultItem = (selectedItem: Key | null) => {
        if (selectedItem) {
            const defaultItem = dropdownData.find((item: DropDown) => item.id == selectedItem);
            return defaultItem?.label;
        } else return dropdownData[0].label;
    };

    return (
        <div className={styles.dropdownSelector + ' ' + classDropdownWrapper}>
            <div className={classDropdownHeader} onClick={() => setOpen(!isDropdownOpen)}>
                {renderDefaultItem(selectedItem)}
                <div className={classIconRight}>
                    <Icon type={IconType.Down} />
                </div>
            </div>
            <div className={classDropdownBody} onClick={() => setOpen(false)}>
                {[...(dropdownData || [])].map((item: DropDown) => {
                    if (item.id !== selectedItem) {
                        return (
                            <div key={item.id} className="dropdown-item" id={item.id.toString()} onClick={(e: any) => handleItemClick(e.target.id)}>
                                {/* <a href={item.urlRedirect} rel="noopener" target="_self"> */}
                                {item.label}
                                {/* </a> */}
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default Dropdown;
