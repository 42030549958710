import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { getPlaceholderBuilderImage, getResizedBuilderImage, ImageResizeParameters } from '../../helpers/BuilderHelper';
import { useInView } from 'react-intersection-observer';

type LazyBackgroundImageProps = {
    imageUrl: string | undefined;

    imageWidth?: number;

    className?: string;
} & HTMLAttributes<HTMLElement>;

export const LazyBackgroundImage = (props: LazyBackgroundImageProps) => {
    const { className = '', imageUrl, imageWidth, ...rest } = props;

    const { ref, inView } = useInView();

    const [wasSeen, setWasSeen] = useState(false);
    const [currentImage, setCurrentImage] = useState(getPlaceholderBuilderImage(imageUrl));

    useEffect(() => {
        if (inView && !wasSeen) {
            setWasSeen(true);
        }
    }, [inView]);

    useEffect(() => {
        let cancelled = false;

        const resizeParams: ImageResizeParameters = {};

        if (imageWidth && imageWidth > 1) {
            resizeParams.width = imageWidth;
        }

        if (wasSeen) {
            const resizedImage = getResizedBuilderImage(imageUrl, resizeParams);

            const loadingImage = new Image();
            loadingImage.onload = () => {
                if (!cancelled) {
                    setCurrentImage(resizedImage);
                }
            };
            loadingImage.src = resizedImage ?? '';
        }

        return () => {
            cancelled = true;
        };
    }, [wasSeen, imageUrl, imageWidth]);

    return <div ref={ref} className={className} {...rest} style={{ ...rest?.style, backgroundImage: `url('${currentImage}')` }} />;
};
