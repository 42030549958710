import React from 'react';

interface Props {}

 const ArrowRightBig = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 12.5H22.5" stroke="black" strokeMiterlimit="10" />
            <path d="M15.5 5.5L22.5 12.5L15.5 19.5" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};
export default ArrowRightBig;
