import React from 'react';

interface Props {}

const CreditCard = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 10.5H6.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 10.5H12.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 6.5H0.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M14.5 13.5H1.5C0.948 13.5 0.5 13.052 0.5 12.5V3.5C0.5 2.948 0.948 2.5 1.5 2.5H14.5C15.052 2.5 15.5 2.948 15.5 3.5V12.5C15.5 13.052 15.052 13.5 14.5 13.5Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default CreditCard;
