import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getQuickSearchResponses, QuickSearchSuggestionItem, QuickSearchSuggestions } from '../../helpers/BuilderHelper';
import { localifyLink, replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import Icon, { IconType } from '../icons';
import styles from './business-header-autocomplete-list.module.scss';

interface Props {
    onClickButtonSearch: React.MouseEventHandler<HTMLElement>;
    isShown: boolean;
}

let debounceTimeout: NodeJS.Timeout | undefined;

const BusinessHeaderAutoCompleteList = (props: Props) => {
    const router = useRouter();
    const { locale } = useLocale();

    const { isShown, onClickButtonSearch } = props;
    const [searchSuggestions, setSearchSuggestions] = useState<QuickSearchSuggestions>();

    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [inputSearchValue, setInputValueSearch] = useState<string>('');

    useEffect(() => {
        let cancelled = false;

        if (inputSearchValue) {
            setShowSuggestions(true);
        }

        setSearchSuggestions(undefined);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
            debounceTimeout = undefined;
        }

        debounceTimeout = setTimeout(() => {
            if (!inputSearchValue) return;
            getQuickSearchResponses(locale, inputSearchValue).then((suggestions) => {
                if (!cancelled) {
                    setSearchSuggestions(suggestions);
                }
            });
        }, 400);

        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            cancelled = true;
        };
    }, [inputSearchValue]);

    const handleChangeInputSearch = (e: any) => {
        setInputValueSearch(e.currentTarget.value);
    };

    const freeTextSearchTargetLink = localifyLink(`/${locale}/business/resources/search/?q=${encodeURIComponent(inputSearchValue)}`, locale);

    const renderSuggestionsListComponent = () => {
        if (!showSuggestions || !inputSearchValue) {
            return null;
        }

        const renderResultsFor = (name: string, items: QuickSearchSuggestionItem[] | undefined) => {
            return (
                <div className={styles.suggestionCategoryGroup}>
                    <div className={styles.suggestionCategoryTitle}>Results for {name}</div>
                    {items === undefined ? (
                        <span className={styles.spinnerLoadMore} />
                    ) : !!items.length ? (
                        <ul className={styles.suggestionCategoryList}>
                            {items.map((item) => {
                                const searchMatch = new RegExp(inputSearchValue, 'i');

                                const regexMatch = item.name.match(searchMatch);

                                const targetMatch = regexMatch ? regexMatch[0] : inputSearchValue;

                                return (
                                    <li key={item.id}>
                                        <a href={replaceOrAddLocale(item.link, locale)} target="_self">
                                            <Icon type={IconType.Search} />
                                            <span
                                                className={styles.suggestionCategoryListText}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.name.replace(targetMatch, `<span class="${styles.suggestionMatch}">${targetMatch}</span>`),
                                                }}
                                            ></span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className={styles.suggestionCategoryNoneFound}>No {name} found</div>
                    )}
                </div>
            );
        };

        return (
            <div className={styles.suggestionsContainer}>
                {renderResultsFor('Categories', searchSuggestions?.categories)}
                {renderResultsFor('Articles', searchSuggestions?.articles)}
                {renderResultsFor('Word', [{ name: inputSearchValue, link: freeTextSearchTargetLink, id: '' }])}
            </div>
        );
    };

    const onKeyUp = (e: any) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            router.push(freeTextSearchTargetLink);
        }
    };

    return (
        <div className={styles.formSearchBlog + ' ' + (isShown ? styles.formShown : '')}>
            <div className={styles.buttonSearch + ' ' + styles.clicked} onClick={onClickButtonSearch}>
                <Icon type={IconType.Search} />
            </div>
            <input type="text" placeholder="eg: SEO" onChange={handleChangeInputSearch} value={inputSearchValue} className={styles.inputSearch} onKeyUp={onKeyUp} />
            <button className={styles.clearInput + ' ' + (isShown ? styles.formShown : '')} onClick={onClickButtonSearch}>
                <Icon type={IconType.RemoveCircle} />
            </button>
            {renderSuggestionsListComponent()}
        </div>
    );
};

export default BusinessHeaderAutoCompleteList;
