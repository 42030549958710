import React from 'react';

interface Props {}

const ImageBackgroundSignUpCard = (props: Props) => {
    return (
        <svg width="557" height="487" viewBox="0 0 557 487" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M520.647 102.133L355.519 9.5642C307.048 -17.6079 246.46 16.3473 246.46 70.7315V80.2278C246.46 88.8063 251.184 96.7465 258.824 101.016L290.001 118.492C299.161 123.639 310.581 117.215 310.581 106.961V83.34C310.581 71.5295 323.725 64.1479 334.241 70.0532L477.27 150.253C487.786 156.158 487.786 170.921 477.27 176.787L334.241 256.986C323.725 262.892 310.581 255.51 310.581 243.699V231.131C310.581 176.747 249.993 142.752 201.481 169.964L36.3529 262.532C-12.1176 289.705 -12.1176 357.695 36.3529 384.867L201.481 477.436C249.952 504.608 310.581 470.653 310.581 416.269V406.772C310.581 398.194 305.857 390.293 298.217 385.984L267.04 368.468C257.879 363.321 246.46 369.745 246.46 379.999V403.62C246.46 415.431 233.316 422.812 222.8 416.907L79.7708 336.707C69.2551 330.802 69.2551 316.039 79.7708 310.134L222.8 229.934C233.316 224.029 246.46 231.41 246.46 243.221V255.789C246.46 310.173 307.048 344.169 355.519 316.957L520.647 224.388C569.118 197.295 569.118 129.305 520.647 102.133Z"
                fill="white"
            />
        </svg>
    );
};

export default ImageBackgroundSignUpCard;
