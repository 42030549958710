import React from 'react';
import styles from './text-skeleton.module.scss';
interface Props {
    size: [number | string, number | string];
}

const TextSkeleton = (props: Props) => {
    const [widthText, heightText] = props.size;
    return <div className={styles.textSkeleton} style={{ height: heightText, width: widthText }}></div>;
};

export default TextSkeleton;
