import React from 'react';

const Download2 = () => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1L12 20" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 13L12 20L5 13" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 20V25H24V20" stroke="black" />
        </svg>
    );
};
export default Download2;
