import { useRouter } from 'next/router';

export enum BusinessUrlPrefix {
    ForRetail = 'for-retailers',
    Business = 'business',
}

export const MARKETING_RESOURCE_CENTRE_PREFIX = '/business/resources/marketing';

export const ALL_BUSINESS_PREFIXES: string[] = Object.values(BusinessUrlPrefix);

export enum Locale {
    USA = 'en-US',
    GreatBritain = 'en-GB',
    Australia = 'en-AU',
    NewZealand = 'en-NZ',
    CanadaEnglish = 'en-CA',
    CanadaFrench = 'fr-CA',
}

export const DEFAULT_LANG = Locale.Australia;

export const LIVE_LOCALES = [Locale.USA, Locale.GreatBritain, Locale.Australia, Locale.NewZealand];

export const EXCLUDE_PAGES = ['/business/access/guides', '/for-retailers/access/guides', '/for-retailers/resources']; // TODO this is a hack to make sure Guides page works as it matches a Category URL otherwise

export const ALL_LOCALES = Object.values(Locale);
export const LOCALE_LINKS: { [key in Locale]?: { home: string; signup: string; businessRoot: BusinessUrlPrefix; host: string } } = {
    [Locale.USA]: {
        signup: 'https://get.afterpay.com/app/',
        home: 'https://afterpay.com/en-US',
        businessRoot: BusinessUrlPrefix.ForRetail,
        host: 'https://afterpay.com',
    },
    [Locale.GreatBritain]: {
        signup: 'https://get.clearpay.co.uk/app',
        home: 'https://www.clearpay.co.uk/en-GB',
        businessRoot: BusinessUrlPrefix.ForRetail,
        host: 'https://www.clearpay.com',
    },
    [Locale.Australia]: {
        signup: 'https://get.afterpay.com/app/',
        home: 'https://www.afterpay.com/en-AU',
        businessRoot: BusinessUrlPrefix.Business,
        host: 'https://afterpay.com',
    },
    [Locale.NewZealand]: {
        signup: 'https://get.afterpay.com/app/',
        home: 'https://www.afterpay.com/en-NZ',
        businessRoot: BusinessUrlPrefix.Business,
        host: 'https://afterpay.com',
    },
    [Locale.CanadaEnglish]: {
        signup: 'https://portal.afterpay.com/nz/login-email',
        home: 'https://www.afterpay.com/en-CA',
        businessRoot: BusinessUrlPrefix.Business,
        host: 'https://afterpay.com',
    },
    [Locale.CanadaFrench]: {
        signup: 'https://portal.afterpay.com/nz/login-email',
        home: 'https://www.afterpay.com/fr-CA',
        businessRoot: BusinessUrlPrefix.Business,
        host: 'https://afterpay.com',
    },
};

export const getLocalePrettyName = (local: Locale) => {
    switch (local) {
        case Locale.USA:
            return 'United States';
        case Locale.Australia:
            return 'Australia';
        case Locale.GreatBritain:
            return 'Great Britain';
        case Locale.NewZealand:
            return 'New Zealand';
        case Locale.CanadaEnglish:
            return 'Canada - English';
        case Locale.CanadaFrench:
            return 'Canada - Français';
    }
};

const LOCALE_MATCH_REGEX = /(\/|).{1,3}\-.{1,3}\//;
const URL_REGREX = /^(http|https):\/\//;

export const isUrl = (url: string) => URL_REGREX.test(url);

export const doesContainLocale = (url: string) => {
    const regex = new RegExp(LOCALE_MATCH_REGEX);

    return regex.test(url);
};

export const getCompanyName = (locale: Locale) => {
    if (locale === Locale.GreatBritain) {
        return 'Clearpay';
    }

    return 'Afterpay';
};

export const stripLocaleFromURL = (url: string): string => {
    return url.replace(LOCALE_MATCH_REGEX, '/');
};

export const replaceLocale = (url: string, newLocale: Locale) => {
    return url.replace(LOCALE_MATCH_REGEX, newLocale);
};

export const replaceOrAddLocale = (url: string, newLocale: Locale): string => {
    if (!url.match(/^(([a-z.\-+])+|(\/\/))(:?).+$/gim)) {
        let newTarget;
        if (doesContainLocale(url)) {
            newTarget = url;
        } else {
            newTarget = `/${newLocale}${url}`;
        }

        return localifyLink(newTarget, newLocale);
    }

    return url;
};

export const localifyLink = (url: string, locale?: Locale) => {
    const matchingRegex = new RegExp(`\/(${ALL_BUSINESS_PREFIXES.join('|')})`);

    if (locale) {
        return url.replace(matchingRegex, '/' + LOCALE_LINKS[locale]?.businessRoot ?? '');
    } else {
        // TODO do some magic to figure out the current locale
    }

    return url;
};

export const getLocaleFromRoute = (path: string): Locale => {
    for (const locale of ALL_LOCALES) {
        if (path.startsWith(`/${locale}`)) {
            return locale;
        }
    }

    // Default
    return Locale.Australia;
};

export const useLocale = () => {
    const router = useRouter();

    const locale: Locale = getLocaleFromRoute(router.asPath);

    return {
        locale,
    };
};
