import React from 'react';
import { Size, useWindowSize } from '../../../hook/useWindowSize';
import { ArticleCardType } from '../../article-card/article-card';
import ImageSkeleton from '../image-skeleton/image-skeleton';
import TextSkeleton from '../text-skeleton/text-skeleton';
import styles from './article-card-skeleton.module.scss';
interface Props {
    type: ArticleCardType;
}

const ArticleCardSkeleton: React.FC<Props> = (props: Props) => {
    const { type } = props;
    const windowSize: Size = useWindowSize();
    const isMobile = (): boolean => {
        const windowWidth: number = windowSize.width || 0;
        if (windowWidth < 768) {
            return true;
        } else {
            return false;
        }
    };

    const renderSmallContent = () => {
        return (
            <div className={styles.articleCardSkeletonLeftContent}>
                <TextSkeleton size={[80, 24]} />
                <div className={styles.articleCardSkeletonLeftContentTitle}>
                    <TextSkeleton size={['100%', isMobile() ? 24 : 32]} />
                    <TextSkeleton size={['100%', isMobile() ? 24 : 32]} />
                    <TextSkeleton size={[180, isMobile() ? 24 : 32]} />
                </div>
                <div className={styles.articleCardSkeletonLeftContentDescription}>
                    <TextSkeleton size={['100%', 16]} />
                    <TextSkeleton size={[isMobile() ? 67 : 90, 16]} />
                </div>
            </div>
        );
    };
    const renderLargeContent = () => {
        if (isMobile()) {
            return renderSmallContent();
        }
        return (
            <div className={styles.articleCardSkeletonLeftContent}>
                <TextSkeleton size={[80, 24]} />
                <div className={styles.articleCardSkeletonLeftContentTitle}>
                    <TextSkeleton size={['100%', 40]} />
                    <TextSkeleton size={['100%', 40]} />
                    <TextSkeleton size={['100%', 40]} />
                    <TextSkeleton size={[180, 40]} />
                </div>
                <div className={styles.articleCardSkeletonLeftContentDescription}>
                    <TextSkeleton size={['100%', 24]} />
                    <TextSkeleton size={['100%', 24]} />
                    <TextSkeleton size={[90, 24]} />
                </div>
                <div className={styles.articleCardSkeletonLeftContentButton}>
                    <TextSkeleton size={[154, 48]} />
                </div>
            </div>
        );
    };
    return (
        <div className={styles.articleCardSkeleton + ' ' + styles[isMobile() ? 'small' : type]}>
            <div className={styles.articleCardSkeletonImage}>
                <ImageSkeleton />
            </div>
            {type === ArticleCardType.Large ? renderLargeContent() : renderSmallContent()}
        </div>
    );
};

export default ArticleCardSkeleton;
