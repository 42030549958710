import React from 'react';

interface Props {}

const PieChart = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 2C3.1 2 0 5.1 0 9C0 12.9 3.1 16 7 16C10.9 16 14 12.9 14 9H7V2Z" fill="black" />
            <path d="M9 0V7H16C16 3.1 12.9 0 9 0Z" fill="black" />
        </svg>
    );
};
export default PieChart;
