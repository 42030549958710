import React from 'react';

interface Props {}

const Email = (props: Props) => {
    return (
        <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1H27V17.5563C27 17.9392 26.844 18.3064 26.5663 18.5772C26.2885 18.8479 25.9119 19 25.5191 19H2.48087C2.08812 19 1.71145 18.8479 1.43374 18.5772C1.15602 18.3064 1 17.9392 1 17.5563V1Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1 1.04899L13.9755 14L27 1" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default Email;
