import React from 'react';
import { getCompanyName, Locale, LOCALE_LINKS, useLocale } from '../../helpers/LangHelper';
import Icon, { IconType } from '../icons';
import ImageBackgroundSignUpCard from './image-background-sign-up-card';
import styles from './sign-up-card.module.scss';

interface Props {}

const SignUpCard = (props: Props) => {
    const { locale } = useLocale();

    return (
        <a href={(LOCALE_LINKS[locale] || LOCALE_LINKS[Locale.USA])!.signup} rel="noreferrer">
            <div className={styles.signUpCard}>
                <div className={styles.backgroundImage}>
                    <ImageBackgroundSignUpCard />
                </div>
                <div className={styles.infoDetail}>
                    <p className={styles.title}>Sign up for {getCompanyName(locale)} Now!</p>
                    <p className={styles.description}>Get {getCompanyName(locale)} for your business.</p>
                </div>
                <div className={styles.buttonSubscribe}>
                    <span>Sign up</span>
                    <Icon type={IconType.ArrowRightMedium} />
                </div>
            </div>
        </a>
    );
};

export default SignUpCard;
