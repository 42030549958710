import React from 'react';

interface Props {}

const RemoveCircle = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#333333" />
            <path d="M5.375 5.375L10.625 10.625" stroke="white" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M5.375 10.625L10.625 5.375" stroke="white" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    );
};

export default RemoveCircle;
