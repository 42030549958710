import React from 'react';

interface Props {}

const Trash = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 9V21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23H6C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21V9"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path d="M1 5H23" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M12 12V18" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M8 12V18" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M16 12V18" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M8 5V1H16V5" stroke="black" strokeMiterlimit="10" />
        </svg>
    );
};
export default Trash;
