import React from 'react';

const Monitor2 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M8 21H16" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 17V21" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default Monitor2;
