import React from 'react';

interface Props {}

const FlashFilled = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M14.9999 6.00016H8.39994L9.99994 1.30016C10.2999 0.300164 9.09995 -0.499837 8.29994 0.300164L0.299945 8.30016C-0.300055 8.90016 0.0999443 10.0002 0.999945 10.0002H7.59994L5.99994 14.7002C5.69994 15.7002 6.89994 16.5002 7.69994 15.7002L15.6999 7.70016C16.2999 7.10016 15.8999 6.00016 14.9999 6.00016Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default FlashFilled;
