import * as React from 'react';
import { DefaultLayoutContext, DefaultLayoutContextType } from '../../contexts/DefaultLayoutContext';
import { FooterMatrix } from '../../helpers/NavHelper';
import { Category } from '../../types/Category';
import { BreadcrumbItem, BreadcrumbType } from '../breadcrumb/breadcrumb';
import BusinessFooter from '../business-footer/business-footer';
import BusinessHeader from '../business-header/business-header';

interface Props {
    children: any;

    categories: Category[];

    breadcrumbs?: BreadcrumbItem[];
    footerItems?: FooterMatrix[];
    title?: string;
    description?: string;
}

const DefaultLayout = (props: Props) => {
    const [type, setType] = React.useState<BreadcrumbType | undefined>();

    const defaultContextValue: DefaultLayoutContextType = {
        breadcrumbs: props.breadcrumbs ?? [],
        type,
        setType,
    };

    return (
        <>
            <DefaultLayoutContext.Provider value={defaultContextValue}>
                <BusinessHeader categories={props.categories} />
                {props.children}

                <BusinessFooter footerData={props.footerItems} />
            </DefaultLayoutContext.Provider>
        </>
    );
};

export default DefaultLayout;
