import React from 'react';

interface Props {}

const PasswordHide = (props: Props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 8C0.5 8 3.5 2.5 8 2.5C12.5 2.5 15.5 8 15.5 8C15.5 8 12.5 13.5 8 13.5C3.5 13.5 0.5 8 0.5 8Z"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M5 8C5 6.343 6.343 5 8 5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 8C11 9.657 9.657 11 8 11" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 15L15 1" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default PasswordHide;
