import React from 'react';

interface Props {}

const AfterPay = (props: Props) => {
    return (
        <svg width="121" height="24" viewBox="0 0 121 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.95859 11.7444C9.95859 9.5873 8.43108 8.07125 6.55474 8.07125C4.6784 8.07125 3.15087 9.61286 3.15087 11.7444C3.15087 13.8503 4.6784 15.4175 6.55474 15.4175C8.43299 15.4155 9.95859 13.9015 9.95859 11.7444ZM9.98543 18.1645V16.4951C9.05972 17.6513 7.68169 18.369 6.03151 18.369C2.60273 18.369 0 15.5433 0 11.7444C0 7.969 2.70239 5.0942 6.10817 5.0942C7.71044 5.0942 9.06163 5.81389 9.98735 6.94257V5.32427H13.0654V18.1645H9.98543Z"
                fill="white"
            />
            <path
                d="M28.0167 15.3133C26.9396 15.3133 26.6406 14.9023 26.6406 13.8248V8.12434H28.6185V5.32427H26.6406V2.19189H23.4859V5.32427H19.4227V4.54363C19.4227 3.46411 19.8233 3.05314 20.9253 3.05314H21.6172V0.561789H20.0993C17.4965 0.561789 16.2699 1.43484 16.2699 4.10513V5.3223H14.5182V8.12237H16.2699V18.1625H19.4246V8.12237H23.4878V14.4147C23.4878 17.0338 24.4633 18.1645 27.0162 18.1645H28.6434V15.3133H28.0167Z"
                fill="white"
            />
            <path
                d="M39.3303 10.5882C39.1061 8.91874 37.7779 7.91787 36.2273 7.91787C34.6749 7.91787 33.3985 8.89318 33.0746 10.5882H39.3303ZM33.0477 12.5919C33.2739 14.4914 34.6002 15.5709 36.2772 15.5709C37.6035 15.5709 38.6307 14.9279 39.2306 13.9015H42.4601C41.7088 16.6229 39.3303 18.369 36.2024 18.369C32.4229 18.369 29.7704 15.6476 29.7704 11.7699C29.7704 7.89231 32.5743 5.09224 36.2791 5.09224C40.0088 5.09224 42.7112 7.91787 42.7112 11.7699C42.7112 12.0531 42.6862 12.3343 42.6364 12.5919H33.0477Z"
                fill="white"
            />
            <path
                d="M62.7683 11.7444C62.7683 9.66399 61.2408 8.07125 59.3644 8.07125C57.4881 8.07125 55.9606 9.61286 55.9606 11.7444C55.9606 13.8503 57.4881 15.4175 59.3644 15.4175C61.2408 15.4155 62.7683 13.8248 62.7683 11.7444ZM52.8557 5.32427H55.9337V6.99369C56.8594 5.81192 58.2355 5.0942 59.8876 5.0942C63.2666 5.0942 65.9192 7.9454 65.9192 11.7188C65.9192 15.4942 63.2168 18.371 59.8129 18.371C58.2356 18.371 56.9342 17.728 56.0334 16.6504V23.448H52.8557V5.32427Z"
                fill="white"
            />
            <path
                d="M77.0143 11.7444C77.0143 9.5873 75.4887 8.07125 73.6104 8.07125C71.7341 8.07125 70.2066 9.61286 70.2066 11.7444C70.2066 13.8503 71.7341 15.4175 73.6104 15.4175C75.4887 15.4155 77.0143 13.9015 77.0143 11.7444ZM77.0411 18.1645V16.4951C76.1154 17.6513 74.7374 18.369 73.0872 18.369C69.6584 18.369 67.0557 15.5433 67.0557 11.7444C67.0557 7.969 69.7581 5.0942 73.1619 5.0942C74.7642 5.0942 76.1154 5.81389 77.0411 6.94257V5.32427H80.1191V18.1645H77.0411Z"
                fill="white"
            />
            <path
                d="M47.3072 6.58273C47.3072 6.58273 48.091 5.0942 50.0096 5.0942C50.8299 5.0942 51.3608 5.38326 51.3608 5.38326V8.65918C51.3608 8.65918 50.2031 7.92574 49.1394 8.07322C48.0757 8.22069 47.403 9.22353 47.4068 10.5646V18.1665H44.2291V5.32624H47.3072V6.58273Z"
                fill="white"
            />
            <path d="M94.4955 5.32426L86.7237 23.4067H83.4464L86.4976 16.4164L81.3381 5.32426H85.0525L88.0711 12.7354L91.1683 5.32426H94.4955Z" fill="white" />
            <path
                d="M119.304 5.03325L111.599 0.471336C109.338 -0.867743 106.511 0.805615 106.511 3.48574V3.95373C106.511 4.37649 106.731 4.76779 107.087 4.97819L108.542 5.83945C108.97 6.09311 109.502 5.77653 109.502 5.27118V4.1071C109.502 3.52507 110.116 3.16129 110.606 3.45231L117.28 7.40466C117.771 7.69568 117.771 8.42323 117.28 8.71228L110.606 12.6646C110.116 12.9556 109.502 12.5919 109.502 12.0098V11.3904C109.502 8.71031 106.675 7.03499 104.412 8.37603L96.7072 12.9379C94.4457 14.277 94.4457 17.6277 96.7072 18.9668L104.412 23.5287C106.673 24.8677 109.502 23.1944 109.502 20.5143V20.0463C109.502 19.6235 109.282 19.2342 108.925 19.0218L107.471 18.1586C107.043 17.9049 106.511 18.2215 106.511 18.7269V19.8909C106.511 20.473 105.897 20.8367 105.407 20.5457L98.7331 16.5934C98.2424 16.3024 98.2424 15.5748 98.7331 15.2838L105.407 11.3314C105.897 11.0404 106.511 11.4042 106.511 11.9862V12.6056C106.511 15.2858 109.338 16.9611 111.599 15.62L119.304 11.0581C121.565 9.72298 121.565 6.37233 119.304 5.03325Z"
                fill="white"
            />
        </svg>
    );
};

export default AfterPay;
