export const setCookie = (name: string, value: string, days?: number): void => {
    let date;
    if (days !== undefined) {
        date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    } else {
        // trick: no expiration date
        date = new Date(2147483647 * 1000);
    }
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

export const getCookie = (name: string): string | undefined => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts && parts.length == 2) {
        const partFist = parts?.pop();
        if (partFist) {
            return partFist.split(';').shift();
        }
        return undefined;
    } else {
        return undefined;
    }
};

export const deleteCookie = (name: string) => {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
};
