import React from 'react';

interface Props {}

const ShoppingBag = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 23.5H2.5L4.5 7.5H20.5L22.5 23.5Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M8.5 10.5V5.5C8.50317 4.44011 8.92561 3.42453 9.67507 2.67507C10.4245 1.92561 11.4401 1.50317 12.5 1.5C13.5599 1.50317 14.5755 1.92561 15.3249 2.67507C16.0744 3.42453 16.4968 4.44011 16.5 5.5V10.5"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ShoppingBag;
